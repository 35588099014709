import React, { useState } from 'react';
import { Box, IconButton, lighten, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import { Info } from '@material-ui/icons';

import { Day, DayStep, DayType } from '../services/api';
import Labels from '../services/Labels';
import DayHelper from '../services/DayHelper';
import LabelType from '../types/LabelType';
import ScoreType from '../types/ScoreType';

import ScoringHelpDialog from './ScoringHelpDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  disabled: {
    filter: `blur(${theme.spacing(1)}px)`,
  },
  svg: {
    display: 'block',
  },
  '@keyframes CircleProgress': {
    '0%': {
      strokeDasharray: '0 100',
    },
  },
  circle: {
    transformOrigin: '50% 50%',

    '& .background': {
      fill: 'none',
    },

    '& .completed': {
      fill: 'none',
      strokeLinecap: 'round',
      animation: '$CircleProgress 1s ease-in-out forwards',
    },
  },
  mobilityCircle: {
    transform: 'scale(1) rotate(-90deg)',

    '& .background': {
      stroke: lighten(theme.palette.primary.main, 0.9),
    },

    '& .completed': {
      stroke: theme.palette.primary.main,
    },
  },
  exerciseCircle: {
    transform: 'scale(0.75) rotate(-90deg)',

    '& .background': {
      stroke: lighten(theme.palette.secondary.main, 0.9),
    },

    '& .completed': {
      stroke: theme.palette.secondary.main,
    },
  },
  helpButton: {
    position: 'absolute',
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),
  },
}));

type ScoreCirclesProps = {
  width?: number | string;
  day?: Day;
  date: Dayjs;
  disabled?: boolean;
  scorePreview?: boolean;
  scoringHelp?: boolean;
};

const ScoreCircles = ({
  width = 160,
  day,
  date,
  disabled = false,
  scorePreview = false,
  scoringHelp = false,
}: ScoreCirclesProps) => {
  const classes = useStyles();
  const [scoringHelpOpen, setScoringHelpOpen] = useState(false);

  return (
    <>
      <Box className={clsx(classes.root, disabled && day?.step !== DayStep.SUBMITTED && classes.disabled)}>
        <svg className={classes.svg} viewBox="0 0 36 36" width={width} id="score-circles">
          <g className={clsx(classes.circle, classes.mobilityCircle)}>
            <circle strokeWidth="3" r="15.915" cx="50%" cy="50%" className="background" />
            {day?.step === DayStep.SUBMITTED && day.score && day.score.mobility > 0 && (
              <circle
                strokeWidth="3"
                r="15.915"
                cx="50%"
                cy="50%"
                className="completed"
                strokeDasharray={`${day.score.mobility}, 100`}
              />
            )}
          </g>

          <g className={clsx(classes.circle, classes.exerciseCircle)}>
            <circle strokeWidth="4" r="15.915" cx="50%" cy="50%" className="background" />
            {day?.step === DayStep.SUBMITTED && day.score && day.score.exercise > 0 && (
              <circle
                strokeWidth="4"
                r="15.915"
                cx="50%"
                cy="50%"
                className="completed"
                strokeDasharray={`${day.score.exercise}, 100`}
              />
            )}
          </g>

          {!scorePreview && DayHelper.isEditable(date) && day?.step !== DayStep.SUBMITTED && (
            <text y="52%" fontSize="4" fontWeight="bold" dominantBaseline="middle">
              <tspan x="50%" textAnchor="middle" dominantBaseline="middle">
                ✏️
              </tspan>
            </text>
          )}

          {day && (day.step === DayStep.SUBMITTED || scorePreview) && day.score && (
            <text y="44%" fontSize="4" fontWeight="bold" dominantBaseline="middle">
              <tspan x="50%" textAnchor="middle">
                {(day.type === DayType.COMMUTED || day.type === DayType.HOME_OFFICE) &&
                  `${day.score.mobility} ${Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI)}`}

                {day.type === DayType.DAY_OFF && Labels.dayType(DayType.DAY_OFF, LabelType.TEXT)}
              </tspan>
              <tspan x="50%" dy="6" textAnchor="middle">
                {day.type === DayType.COMMUTED
                  ? `${day.score.exercise} ${Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI)}`
                  : Labels.dayType(day.type, LabelType.EMOJI)}
              </tspan>
            </text>
          )}
        </svg>
        {scoringHelp && (
          <IconButton className={classes.helpButton} size="small" onClick={() => setScoringHelpOpen(true)}>
            <Info />
          </IconButton>
        )}
      </Box>
      {scoringHelp && <ScoringHelpDialog open={scoringHelpOpen} onClose={() => setScoringHelpOpen(false)} />}
    </>
  );
};

export default ScoreCircles;
