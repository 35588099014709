import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  item: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
  },
  tertiary: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.common.white,
  },
  quaternary: {
    backgroundColor: theme.palette.quaternary.main,
    color: theme.palette.common.white,
  },
}));

const TripPieChartLegend = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Chip className={classes.item} color="secondary" label="Aktive Mobilität" />
      <Chip className={clsx(classes.item, classes.tertiary)} label="Öffentliche/geteilte Verkehrsmittel" />
      <Chip className={clsx(classes.item, classes.quaternary)} label="Motorisierter Individualverkehr" />
    </Box>
  );
};

export default TripPieChartLegend;
