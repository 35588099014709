import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

type PrivateRouteProps = {
  children: ReactNode;
  path: string;
  exact: boolean;
};

const PrivateRoute = ({ children, path, exact }: PrivateRouteProps) => {
  const {
    state: { user },
  } = useStateMachine();

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
