import React from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import { LoginUserDto } from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    alignSelf: 'center',
    margin: 'auto 0',
    maxWidth: theme.spacing(50),
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`,
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  button: {
    float: 'right',
    marginTop: theme.spacing(3),
    minWidth: theme.spacing(15),
  },
  privacyLabel: {
    fontSize: '0.75rem',
  },
}));

type LoginFormProps = {
  error: string | undefined;
  onSubmit: (data: LoginUserDto) => void;
};

const LoginForm = ({ error, onSubmit }: LoginFormProps) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUserDto & { privacy: boolean }>();

  const { ref: emailRef, ...emailRegister } = register('email', { required: true });
  const { ref: passwordRef, ...passwordRegister } = register('password', { required: true });
  const { ref: privacyRef, ...privacyRegister } = register('privacy', { required: true });

  return (
    <Paper elevation={6} className={classes.root} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" className={classes.heading}>
        Login
      </Typography>

      <TextField
        label="E-Mail"
        type="email"
        fullWidth
        margin="dense"
        className={classes.textField}
        variant="outlined"
        error={!!errors.email || !!error}
        inputRef={emailRef}
        {...emailRegister}
      />

      <TextField
        label="Passwort"
        type="password"
        fullWidth
        margin="dense"
        className={classes.textField}
        variant="outlined"
        error={!!errors.password || !!error}
        helperText={error}
        inputRef={passwordRef}
        {...passwordRegister}
      />

      <FormControl error={!!errors.privacy}>
        <FormControlLabel
          classes={{
            label: classes.privacyLabel,
          }}
          control={<Checkbox color="primary" inputRef={privacyRef} {...privacyRegister} />}
          label={
            <>
              Ich stimme der{' '}
              <NavLink to="/privacy" target="_blank">
                Datenschutzerklärung
              </NavLink>{' '}
              zu und willige zur Nutzung meiner anonymisierten Daten in der Abschlussarbeit ein
            </>
          }
        />
        {errors.privacy && <FormHelperText>Eine Teilnahme ist nur mit Zustimmung möglich</FormHelperText>}
      </FormControl>

      <Button type="submit" className={classes.button} color="primary" variant="outlined">
        Login
      </Button>
    </Paper>
  );
};

export default LoginForm;
