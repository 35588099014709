import React from 'react';

import Login from '../containers/Login';
import useTitle from '../hooks/useTitle';

const LoginPage = () => {
  useTitle();

  return <Login />;
};

export default LoginPage;
