import React, { memo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Box, makeStyles } from '@material-ui/core';

import Labels from '../services/Labels';
import theme, { nivoTheme } from '../setup/theme';
import { Mobility, TripSegmentDto } from '../services/api';
import LabelType from '../types/LabelType';

import NivoTooltip from './NivoTooltip';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: theme.spacing(26),
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  totalOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

const getInitialData = () =>
  Object.values(Mobility).reduce(
    (data, mobility) => ({
      ...data,
      [mobility]: {
        id: mobility,
        value: 0,
      },
    }),
    {} as { [key in Mobility]: { id: Mobility; value: number } },
  );

type TripPieChartProps = {
  size?: 'small' | 'medium';
  segments: TripSegmentDto[];
};

const TripPieChart = ({ size = 'medium', segments = [] }: TripPieChartProps) => {
  const classes = useStyles();
  const colors: Record<Mobility, string> = {
    [Mobility.WALK]: theme.palette.secondary.main,
    [Mobility.BICYCLE]: theme.palette.secondary.main,
    [Mobility.ELECTRIC_BICYCLE]: theme.palette.secondary.main,
    [Mobility.BUS]: theme.palette.tertiary.main,
    [Mobility.TRAM]: theme.palette.tertiary.main,
    [Mobility.METRO]: theme.palette.tertiary.main,
    [Mobility.TRAIN]: theme.palette.tertiary.main,
    [Mobility.CARPOOL]: theme.palette.tertiary.main,
    [Mobility.ELECTRIC_MOTORCYCLE]: theme.palette.quaternary.main,
    [Mobility.ELECTRIC_SCOOTER]: theme.palette.quaternary.main,
    [Mobility.ELECTRIC_CAR]: theme.palette.quaternary.main,
    [Mobility.MOTORCYCLE]: theme.palette.quaternary.main,
    [Mobility.SCOOTER]: theme.palette.quaternary.main,
    [Mobility.CAR]: theme.palette.quaternary.main,
  };

  const data = Object.values(
    segments.reduce((dataObject, { mobility, distance }) => {
      if (mobility && distance && distance > 0) {
        const newDataObject = dataObject;
        newDataObject[mobility].value += distance;

        return newDataObject;
      }

      return dataObject;
    }, getInitialData()),
  ).filter(({ value }) => value > 0);

  const totalDistance = data.reduce((total, { value }) => total + value, 0);

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <ResponsivePie
          theme={nivoTheme}
          arcLabelsTextColor="white"
          data={data}
          valueFormat={(value) => `${value.toLocaleString()} km`}
          colors={(datum) => colors[datum.id as Mobility]}
          margin={size !== 'small' ? { top: 16, right: 16, bottom: 16, left: 16 } : {}}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          arcLabel={({ id, formattedValue }) => `${Labels.mobility(id as Mobility, LabelType.EMOJI)} ${formattedValue}`}
          enableArcLinkLabels={false}
          isInteractive={size !== 'small'}
          tooltip={({ datum: { id, formattedValue } }) => (
            <NivoTooltip>
              {Labels.mobility(id as Mobility, LabelType.EMOJI_TEXT)} {formattedValue}
            </NivoTooltip>
          )}
        />

        {totalDistance > 0 && <Box className={classes.totalOverlay}>{totalDistance.toLocaleString()} km</Box>}
      </Box>
    </Box>
  );
};

export default memo(TripPieChart);
