/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Badge } from '../models/Badge';
import { request as __request } from '../core/request';

export class BadgesService {

    /**
     * @returns Badge
     * @throws ApiError
     */
    public static async findAllForUser(): Promise<Array<Badge>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/badges`,
        });
        return result.body;
    }

}