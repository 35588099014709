import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}));

type ApiErrorProps = {
  error?: boolean;
};

const ApiError = ({ error }: ApiErrorProps) => {
  const classes = useStyles();

  return error ? (
    <Alert className={classes.root} elevation={6} severity="warning">
      Es ist ein Fehler aufgetreten, versuche es später erneut.
    </Alert>
  ) : null;
};

export default ApiError;
