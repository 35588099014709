import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emoji: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    lineHeight: `${theme.spacing(6)}px`,
    fontSize: '1.5rem',
    position: 'relative',
    borderRadius: '50%',
    border: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      lineHeight: `${theme.spacing(8)}px`,
      fontSize: '2rem',
    },
  },
}));

type TripWrapperPlaceProps = { emoji: string; title: string };

const TripWrapperPlace = ({ emoji, title }: TripWrapperPlaceProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <Box className={classes.emoji}>{emoji}</Box>
      </Grid>
      <Grid item xs>
        <Typography variant="h3" component="div">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TripWrapperPlace;
