import React from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import Labels from '../services/Labels';
import { TripSegmentDto } from '../services/api';
import LabelType from '../types/LabelType';

type TripViewProps = { segments: TripSegmentDto[] };

const TripView = ({ segments }: TripViewProps) => (
  <Grid container spacing={4} wrap="nowrap">
    <Grid item xs>
      <List style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {segments?.map((segment) => (
          <ListItem key={`${segment.distance}-${segment.mobility}`}>
            <ListItemText
              primary={`${segment.distance.toLocaleString()} km ${Labels.mobility(
                segment.mobility,
                LabelType.EMOJI_TEXT,
              )}`}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  </Grid>
);

export default TripView;
