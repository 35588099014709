import React from 'react';
import { Check, ChevronLeft } from '@material-ui/icons';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import party from 'party-js';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Day } from '../services/api';

import SubmitButtons from './SubmitButtons';
import TripReview from './TripReview';
import PageTour from './PageTour';

const useStyles = makeStyles((theme) => ({ alert: { marginTop: theme.spacing(4) } }));

type TripSubmitProps = { day: Day; onBack: () => void; onNext: () => void };

const TripSubmit = ({ day, onBack, onNext }: TripSubmitProps) => {
  const classes = useStyles();

  return (
    <>
      <Box data-tour="trip-submit">
        <TripReview day={day} />

        <Alert severity="warning" className={classes.alert}>
          <AlertTitle>Sind alle Angaben korrekt?</AlertTitle>
          Deine Angaben können nachträglich nicht geändert werden.
        </Alert>
      </Box>

      <SubmitButtons
        left={
          <Button startIcon={<ChevronLeft />} variant="contained" disableElevation onClick={onBack}>
            Zurück
          </Button>
        }
        right={
          <Button
            endIcon={<Check />}
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => {
              if (day.score.mobility > 0 || day.score.exercise > 0)
                party.confetti(document.body, {
                  count: party.variation.range(window.innerWidth / 20, window.innerWidth / 10),
                });

              onNext();
            }}
            data-tour="trip-submit-submit"
          >
            Fertig
          </Button>
        }
      />

      <PageTour
        steps={[
          {
            selector: '[data-tour="trip-submit"]',
            content: (
              <Typography>
                Hier siehst du deinen Tag nochmal im Überblick und wie viele Punkt du für ihn erhältst bevor du ihn
                final abschließt.
              </Typography>
            ),
          },
          {
            selector: '[data-tour="trip-submit-submit"]',
            content: (
              <Typography>
                Passt alles? Dann bist du nur noch einen Klick entfernt von deinen Punkten!
                <br />
                <strong>Achtung: Du kannst den Tag dann nicht mehr editieren.</strong>
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};

export default TripSubmit;
