import React from 'react';
import Tour, { ReactourProps } from 'reactour';
import { useStateMachine } from 'little-state-machine';

import theme from '../setup/theme';
import { updateTourOpen } from '../setup/global-state';

type PageTourProps = Pick<ReactourProps, 'steps'>;

const PageTour = ({ steps }: PageTourProps) => {
  const {
    state: { tourOpen },
    actions,
  } = useStateMachine({ updateTourOpen });

  return (
    <Tour
      steps={steps}
      isOpen={tourOpen}
      onRequestClose={() => actions.updateTourOpen(false)}
      accentColor={theme.palette.primary.main}
      rounded={theme.shape.borderRadius}
    />
  );
};

export default PageTour;
