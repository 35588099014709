import React, { ReactNode } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),

      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(8),
      },
    },
  },
}));

type PaperContainerProps = {
  children?: ReactNode;
  className?: string;
};

const PaperContainer = ({ children, className }: PaperContainerProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={6} className={clsx(classes.root, className)}>
      {children}
    </Paper>
  );
};

export default PaperContainer;
