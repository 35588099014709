import React, { useState } from 'react';
import { Box, Button, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  id: {
    fontFamily: 'Monospace',
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.grey[200],
    width: 'fit-content',
    borderRadius: '4px',
    userSelect: 'all',
    marginRight: theme.spacing(2),
  },
}));

type UserIdProps = {
  userId: string;
};

const UserId = ({ userId }: UserIdProps) => {
  const classes = useStyles();
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Studien-Token
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography className={classes.id}>{userId}</Typography>
        <Button
          endIcon={<FileCopyOutlined />}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(userId);
              setSuccessOpen(true);
            } catch {
              setErrorOpen(true);
            }
          }}
        >
          Kopieren
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={successOpen}
        autoHideDuration={5000}
        onClose={() => setSuccessOpen(false)}
      >
        <Alert elevation={3} severity="success">
          Kopiert
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={errorOpen}
        autoHideDuration={5000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert elevation={3} severity="error">
          Kopieren nicht möglich
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserId;
