import React from 'react';
import { Check } from '@material-ui/icons';
import { makeStyles, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { UpdateUserDto, UserService } from '../services/api';
import AlertDialog from '../components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

type ChangePasswordDialogProps = { open: boolean; onClose: () => void; userId: string };

const ChangePasswordDialog = ({ open, onClose, userId }: ChangePasswordDialogProps) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setError,
    reset,
  } = useForm<Pick<UpdateUserDto, 'oldPassword' | 'newPassword'> & { repeatNewPassword?: string }>();

  const { ref: oldPasswordRef, ...oldPasswordRegister } = register('oldPassword', {
    required: true,
  });
  const { ref: newPasswordRef, ...newPasswordRegister } = register('newPassword', {
    required: true,
    minLength: 8,
  });
  const { ref: repeatNewPasswordRef, ...repeatNewPasswordRegister } = register('repeatNewPassword', {
    required: true,
    validate: (value) => value === getValues('newPassword') || 'Neues Passwort stimmt nicht überein',
  });

  return (
    <AlertDialog
      id="change-password-dialog"
      open={open}
      onClose={onClose}
      maxWidth="xs"
      title="Passwort ändern"
      submitIcon={<Check />}
      submitLabel="Speichern"
    >
      <form
        id="change-password-dialog-form"
        onSubmit={handleSubmit(async (data) => {
          try {
            await UserService.update({
              id: userId,
              requestBody: { oldPassword: data.oldPassword, newPassword: data.newPassword },
            });

            reset();
            onClose();
          } catch (responseError) {
            if (responseError.status === 401) {
              setError('oldPassword', { message: 'Bitte überprüfen Deine Eingabe' });
            }
          }
        })}
      >
        <TextField
          label="Aktuelles Passwort"
          type="password"
          fullWidth
          margin="dense"
          className={classes.textField}
          variant="outlined"
          error={!!errors.oldPassword}
          helperText={errors?.oldPassword?.message}
          inputRef={oldPasswordRef}
          {...oldPasswordRegister}
        />

        <TextField
          label="Neues Passwort"
          type="password"
          fullWidth
          margin="dense"
          className={classes.textField}
          variant="outlined"
          error={!!errors.newPassword}
          helperText="Mindestens 8 Zeichen"
          inputRef={newPasswordRef}
          {...newPasswordRegister}
        />

        <TextField
          label="Neues Passwort wiederholen"
          type="password"
          fullWidth
          margin="dense"
          className={classes.textField}
          variant="outlined"
          error={!!errors.repeatNewPassword}
          helperText={errors.repeatNewPassword?.message}
          inputRef={repeatNewPasswordRef}
          {...repeatNewPasswordRegister}
        />
      </form>
    </AlertDialog>
  );
};

export default ChangePasswordDialog;
