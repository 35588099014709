import React, { FC, useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import LoginForm from '../components/LoginForm';
import { AuthService } from '../services/api';
import { updateUser } from '../setup/global-state';

const Login: FC = () => {
  const [error, setError] = useState<string | undefined>();
  const {
    state: { user },
    actions,
  } = useStateMachine({ updateUser });
  const history = useHistory();
  const location = useLocation<{ from?: { pathname?: string } }>();

  useEffect(() => {
    (async () => {
      try {
        actions.updateUser(await AuthService.getProfile());
      } catch {
        // Nothing to do
      }
    })();
  }, [actions]);

  return user ? (
    <Redirect to={location?.state?.from?.pathname || '/'} />
  ) : (
    <LoginForm
      error={error}
      onSubmit={async (requestBody) => {
        try {
          actions.updateUser(await AuthService.login({ requestBody }));

          const { from = { pathname: '/' } } = location.state;
          history.replace(from);
        } catch (responseError) {
          if (responseError.status === 401) {
            setError('Bitte überprüfen Deine Eingabe');
          } else {
            setError('Es ist ein Fehler aufgetreten.');
          }
        }
      }}
    />
  );
};

export default Login;
