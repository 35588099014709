import dayjs, { Dayjs } from 'dayjs';

class DayHelper {
  public static isSameOrBeforeToday(date: Dayjs) {
    return date.isSameOrBefore(dayjs(), 'date');
  }

  public static isInLastWeek(date: Dayjs) {
    return date.isAfter(dayjs().subtract(8, 'days'), 'date');
  }

  public static isEditable(date: Dayjs) {
    return DayHelper.isInLastWeek(date) && DayHelper.isSameOrBeforeToday(date);
  }
}

export default DayHelper;
