/* eslint-disable react/jsx-props-no-spreading */

import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core';
import { Clear, DragIndicator } from '@material-ui/icons';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import Labels from '../services/Labels';
import { Mobility, TripSegmentDto } from '../services/api';
import LabelType from '../types/LabelType';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1)}px 0`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `${theme.spacing(0.25)}px solid ${theme.palette.common.white}`,
    transition: 'border-color ease 300ms',
  },
  rowActive: {
    borderColor: theme.palette.grey[100],
  },
  rowItem: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  inputs: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

    [theme.breakpoints.down('sm')]: {
      '& $rowItem:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

type TripFormRowProps = {
  field: FieldArrayWithId<{ segments: TripSegmentDto[] }>;
  index: number;
  onRemove: () => void;
};

const TripFormRow = ({ field, index, onRemove }: TripFormRowProps) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext<{ segments: TripSegmentDto[] }>();
  const { ref: distanceRef, ...distanceRegister } = register(`segments.${index}.distance` as const, {
    required: true,
    valueAsNumber: true,
    min: 0,
  });
  const { ref: mobilityRef, ...mobilityRegister } = register(`segments.${index}.mobility` as const, { required: true });

  return (
    <Draggable draggableId={field.id} index={index}>
      {(draggableProvided, snapshot) => (
        <div
          className={clsx(classes.row, snapshot.isDragging && classes.rowActive)}
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <IconButton className={classes.rowItem} size="small" {...draggableProvided.dragHandleProps}>
            <DragIndicator />
          </IconButton>

          <Box className={clsx(classes.rowItem, classes.inputs)}>
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Distanz"
              InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment> }}
              inputProps={{ min: 0.1, step: 0.1 }}
              variant="outlined"
              className={clsx(classes.rowItem, classes.textField)}
              defaultValue={field.distance}
              inputRef={distanceRef}
              {...distanceRegister}
              error={!!errors?.segments?.[index]?.distance}
            />

            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              className={clsx(classes.rowItem, classes.textField)}
              error={!!errors?.segments?.[index]?.mobility}
            >
              <InputLabel
                htmlFor={`segments.${index}.mobility`}
                margin="dense"
                error={!!errors?.segments?.[index]?.mobility}
              >
                Mobilität
              </InputLabel>
              <Select
                native
                margin="dense"
                label="Mobilität"
                inputProps={{
                  id: `segments.${index}.mobility`,
                }}
                defaultValue={field.mobility}
                inputRef={mobilityRef}
                {...mobilityRegister}
                error={!!errors?.segments?.[index]?.mobility}
              >
                {Object.values(Mobility).map((mobility) => (
                  <option key={mobility} value={mobility}>
                    {Labels.mobility(mobility, LabelType.EMOJI_TEXT)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <IconButton className={classes.rowItem} size="small" onClick={onRemove}>
            <Clear />
          </IconButton>
        </div>
      )}
    </Draggable>
  );
};

export default memo(TripFormRow);
