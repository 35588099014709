import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { memo, useState } from 'react';
import { Cancel, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Mobility, Trip, TripDirection, TripSegmentDto, TripService } from '../services/api';
import useApi from '../hooks/useApi';
import DeleteTripDialog from '../containers/DeleteTripDialog';
import Labels from '../services/Labels';
import LabelType from '../types/LabelType';

import TripPieChart from './TripPieChart';
import TripForm from './TripForm';
import TripWrapperSingle from './TripWrapperSingle';
import SubmitButtons from './SubmitButtons';
import PageTour from './PageTour';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
  items: {
    display: 'flex',
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  itemWrapper: {
    position: 'relative',

    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },

    '&:last-child': {
      marginRight: theme.spacing(4),
    },
  },
  item: {
    flexShrink: 0,
    height: theme.spacing(40),
    width: theme.spacing(30),
    padding: theme.spacing(4),
    border: `${theme.spacing(0.5)}px solid ${theme.palette.grey[300]}`,

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  emojiLabel: {
    fontSize: '2rem',
  },
  tripName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

enum TripWizardStep {
  SELECT,
  FORM,
}

type TripWizardProps = {
  direction: TripDirection;
  segments?: TripSegmentDto[];
  previousSegments?: TripSegmentDto[];
  onSubmit: (segments: TripSegmentDto[]) => Promise<void>;
  onBack: () => void;
  onNext: () => void;
};

const TripWizard = ({ direction, segments = [], previousSegments = [], onSubmit, onBack, onNext }: TripWizardProps) => {
  const classes = useStyles();
  const [step, setStep] = useState(segments.length > 0 ? TripWizardStep.FORM : TripWizardStep.SELECT);
  const [deleteTrip, setDeleteTrip] = useState<Trip | undefined>();
  const { data = [], revalidate } = useApi('/api/v1/trips', () => TripService.findAllForUser());
  const methods = useForm<{ segments: TripSegmentDto[] }>({
    defaultValues: {
      segments,
    },
  });
  const fieldArray = useFieldArray({
    control: methods.control,
    name: 'segments',
  });

  return (
    <>
      {step === TripWizardStep.SELECT && (
        <>
          <TripWrapperSingle direction={direction} dataTour="trip-wizard-select">
            <Box className={classes.items}>
              <Box className={classes.itemWrapper}>
                <Button
                  className={classes.item}
                  onClick={() => {
                    methods.setValue('segments', [{ distance: 0, mobility: Mobility.WALK as Mobility }]);

                    setStep(TripWizardStep.FORM);
                  }}
                >
                  <Box className={classes.emojiLabel}>🆕</Box>
                  <Typography variant="h3">Neu anlegen</Typography>
                </Button>
              </Box>

              {direction === TripDirection.TO_HOME && (
                <Box className={classes.itemWrapper}>
                  <Button
                    className={classes.item}
                    onClick={() => {
                      methods.setValue('segments', [...previousSegments].reverse());

                      setStep(TripWizardStep.FORM);
                    }}
                  >
                    <Box className={classes.emojiLabel}>↔️</Box>
                    <Typography variant="h3">Arbeitsweg umkehren</Typography>
                  </Button>
                </Box>
              )}

              {data.map((trip) => (
                <Box key={trip.id} className={classes.itemWrapper}>
                  <Button
                    className={classes.item}
                    onClick={() => {
                      const tripSegments =
                        trip.direction === direction ? [...trip.segments] : [...trip.segments].reverse();
                      methods.setValue('segments', tripSegments);

                      setStep(TripWizardStep.FORM);
                    }}
                  >
                    <TripPieChart size="small" segments={trip.segments} />
                    <Typography variant="h3" className={classes.tripName}>
                      {trip.name}
                    </Typography>
                  </Button>
                  <IconButton className={classes.deleteButton} size="small" onClick={() => setDeleteTrip(trip)}>
                    <Cancel />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </TripWrapperSingle>

          <SubmitButtons
            left={
              <Button startIcon={<ChevronLeft />} variant="contained" disableElevation onClick={onBack}>
                Zurück
              </Button>
            }
            right={
              <Button endIcon={<ChevronRight />} variant="contained" disableElevation color="secondary" disabled>
                Weiter
              </Button>
            }
          />
        </>
      )}

      {step === TripWizardStep.FORM && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(async ({ segments: newSegments }) => {
              await onSubmit(newSegments);

              onNext();
            })}
          >
            <TripForm direction={direction} fieldArray={fieldArray} />
            <SubmitButtons
              left={
                <Button
                  startIcon={<ChevronLeft />}
                  variant="contained"
                  disableElevation
                  onClick={() => setStep(TripWizardStep.SELECT)}
                >
                  Zurück
                </Button>
              }
              right={
                <Button
                  endIcon={<ChevronRight />}
                  variant="contained"
                  disableElevation
                  color="secondary"
                  type="submit"
                  data-tour="trip-wizard-submit"
                >
                  Weiter
                </Button>
              }
            />
          </form>
        </FormProvider>
      )}

      <DeleteTripDialog
        trip={deleteTrip}
        onClose={async () => {
          await revalidate();
          setDeleteTrip(undefined);
        }}
      />

      <PageTour
        steps={[
          ...(step === TripWizardStep.SELECT
            ? [
                {
                  selector: '[data-tour="trip-wizard-select"]',
                  content: (
                    <Typography>
                      Hier kannst du entweder einen Arbeitsweg neu anlegen oder direkt einen aus deinen Vorlagen
                      auswählen. Wenn du noch keine Vorlagen hast, kannst du sie später aus diesem Tag erstellen.
                    </Typography>
                  ),
                },
              ]
            : []),
          ...(step === TripWizardStep.FORM
            ? [
                {
                  selector: '[data-tour="trip-form"]',
                  content: (
                    <Typography>
                      Gib hier deinen {Labels.tripDirection(direction, LabelType.TEXT)} mit den entsprechenden
                      Mobilitätsmodalitäten und der jeweiligen Distanz an. Die Distanzen kannst du mit einer beliebigen
                      Kartenanwendung bestimmen.
                    </Typography>
                  ),
                },
                {
                  selector: '[data-tour="trip-wizard-submit"]',
                  content: (
                    <Typography>
                      Wenn du deinen {Labels.tripDirection(direction, LabelType.TEXT)} vollständig abgebildet hast, geht
                      es hier weiter.
                    </Typography>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default memo(TripWizard);
