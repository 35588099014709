import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import PaperContainer from '../components/PaperContainer';
import useApi from '../hooks/useApi';
import { BadgesService, BadgeType, BadgeValueDto } from '../services/api';
import BadgeCard from '../components/BadgeCard';
import PaperContainerHeader from '../components/PaperContainerHeader';

const useStyles = makeStyles((theme) => ({
  badgeWrapper: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  count: {
    fontWeight: 'bold',
    fontSize: '4rem',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(8),
    },
  },
}));

const BadgeOverview = () => {
  const classes = useStyles();

  const { data = [] } = useApi('/api/v1/badges', () => BadgesService.findAllForUser());

  const renderDistanceBadges = (type: BadgeType, distances: number[]) => (
    <Grid key={type} container spacing={2} justifyContent="center" className={classes.section}>
      {distances.map((value) => {
        const badgeItem = data.find(
          (badge) => badge.type === type && (badge.properties as BadgeValueDto).value === value,
        );

        return (
          <Grid key={`${type}-${value}`} item xs={12}>
            <Box className={classes.badgeWrapper}>
              <BadgeCard
                badge={badgeItem || { type, properties: { value } }}
                disabled={!badgeItem}
                createdAt={!!badgeItem}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <PaperContainer>
      <PaperContainerHeader title="Auszeichnungen" />
      <Grid container spacing={2} justifyContent="center" className={classes.section}>
        {[BadgeType.PERFECT_DAY].map((type) => {
          const badges = data.filter((badge) => badge.type === type);
          const count = badges.length;

          return (
            <Grid key={type} item xs={12}>
              <Box className={classes.badgeWrapper}>
                <Box className={classes.count}>{count}x</Box>
                <BadgeCard badge={{ type }} disabled={count === 0} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {renderDistanceBadges(BadgeType.EXERCISE_DISTANCE, [50, 250, 1000])}

      {renderDistanceBadges(BadgeType.PUBLIC_TRANSPORT_DISTANCE, [100, 500, 5000])}
    </PaperContainer>
  );
};

export default BadgeOverview;
