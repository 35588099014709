import React from 'react';
import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { UseFormSetValue } from 'react-hook-form';
import clsx from 'clsx';

import Labels from '../services/Labels';
import { LeaderboardAggregation, LeaderboardFilterSettings } from '../types/LeaderboardFilterSettings';
import ScoreType from '../types/ScoreType';
import LabelType from '../types/LabelType';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  filterInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  filterLabel: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  filterItem: {
    fontWeight: 'bold',
    fontSize: theme.typography.body2.fontSize,
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,

    [theme.breakpoints.up('sm')]: {
      margin: 0,

      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

type LeaderboardFilterProps = {
  setValue: UseFormSetValue<LeaderboardFilterSettings>;
  aggregation: LeaderboardAggregation;
  sort: ScoreType;
};

const LeaderboardFilter = ({ setValue, aggregation, sort }: LeaderboardFilterProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.filter}>
        <Box className={classes.filterInner} data-tour="leaderboard-filter-aggregation">
          <Typography className={clsx(classes.filterLabel, classes.filterItem)}>Rangliste:</Typography>
          <Chip
            className={classes.filterItem}
            label="👥 Teams"
            clickable
            variant="outlined"
            color={aggregation === LeaderboardAggregation.ORGANIZATION_TEAMS ? 'primary' : 'default'}
            onClick={() => setValue('aggregation', LeaderboardAggregation.ORGANIZATION_TEAMS)}
          />
          <Chip
            className={classes.filterItem}
            label="👤 Mein Team"
            clickable
            variant="outlined"
            color={aggregation === LeaderboardAggregation.TEAM_USERS ? 'primary' : 'default'}
            onClick={() => setValue('aggregation', LeaderboardAggregation.TEAM_USERS)}
          />
          <Chip
            className={classes.filterItem}
            label="👤 Meine Organisation"
            clickable
            variant="outlined"
            color={aggregation === LeaderboardAggregation.ORGANIZATION_USERS ? 'primary' : 'default'}
            onClick={() => setValue('aggregation', LeaderboardAggregation.ORGANIZATION_USERS)}
          />
        </Box>
      </Box>

      <Box className={classes.filter}>
        <Box className={classes.filterInner} data-tour="leaderboard-filter-sort">
          <Typography className={clsx(classes.filterLabel, classes.filterItem)}>Sortieren nach:</Typography>
          <Chip
            className={classes.filterItem}
            label={Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI_TEXT)}
            clickable
            variant="outlined"
            color={sort === ScoreType.MOBILITY ? 'primary' : 'default'}
            onClick={() => setValue('sort', ScoreType.MOBILITY)}
          />
          <Chip
            className={classes.filterItem}
            label={Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI_TEXT)}
            clickable
            variant="outlined"
            color={sort === ScoreType.EXERCISE ? 'primary' : 'default'}
            onClick={() => setValue('sort', ScoreType.EXERCISE)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LeaderboardFilter;
