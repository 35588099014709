import React from 'react';
import { Datum, ResponsiveCalendar } from '@nivo/calendar';
import { Box, lighten, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import globalTheme, { nivoTheme } from '../setup/theme';
import ScoreType from '../types/ScoreType';
import { Day, DayStep } from '../services/api';
import Labels from '../services/Labels';
import LabelType from '../types/LabelType';

import NivoTooltip from './NivoTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: theme.spacing(80),

    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(32),
    },
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

type ScoreCalendarProps = { days: Day[]; scoreType: ScoreType };

const ScoreCalendar = ({ days, scoreType }: ScoreCalendarProps) => {
  const classes = useStyles();
  const color = {
    [ScoreType.MOBILITY]: globalTheme.palette.primary.main,
    [ScoreType.EXERCISE]: globalTheme.palette.secondary.main,
  }[scoreType];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <ResponsiveCalendar
          theme={nivoTheme}
          direction={matches ? 'vertical' : 'horizontal'}
          data={days
            .filter(({ step }) => step === DayStep.SUBMITTED)
            .map((day) => ({
              day: day.date,
              value:
                day.score[
                  {
                    [ScoreType.MOBILITY]: 'mobility' as const,
                    [ScoreType.EXERCISE]: 'exercise' as const,
                  }[scoreType]
                ],
            }))}
          onClick={(datum) => {
            if ((datum as Datum).value !== undefined) {
              history.push(`/days/${datum.day}`);
            }
          }}
          from="2021-01-01"
          to="2021-12-31"
          minValue={0}
          maxValue={100}
          emptyColor="#eeeeee"
          colors={[
            lighten(color, 0.9),
            lighten(color, 0.8),
            lighten(color, 0.6),
            lighten(color, 0.4),
            lighten(color, 0.2),
            color,
          ]}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'row',
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: 'right-to-left',
            },
          ]}
          tooltip={({ day, value }) => (
            <NivoTooltip>
              {dayjs(day).format('DD.MM.YYYY')} {Labels.scoreType(scoreType, LabelType.EMOJI)} {value}
            </NivoTooltip>
          )}
        />
      </Box>
    </Box>
  );
};

export default ScoreCalendar;
