import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Switch, Route, useLocation } from 'react-router-dom';
import { animated, useTransition } from '@react-spring/web';

import Navigation from './components/Navigation';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import LeaderboardPage from './pages/LeaderboardPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import DayPage from './pages/DayPage';
import PrivacyPage from './pages/PrivacyPage';
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(11),
    },
  },
  animated: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation<{ pathname: string }>();

  const transition = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 400,
    },
  });

  return (
    <Box className={classes.root}>
      <Navigation />
      <Container className={classes.container}>
        {transition((style, item) => (
          <animated.div className={classes.animated} style={style} key={location.pathname}>
            <Switch location={item}>
              <Route exact path="/login">
                <LoginPage />
              </Route>
              <Route exact path="/privacy">
                <PrivacyPage />
              </Route>
              <PrivateRoute exact path="/">
                <HomePage />
              </PrivateRoute>
              <PrivateRoute exact path="/days/:date">
                <DayPage />
              </PrivateRoute>
              <PrivateRoute exact path="/leaderboards">
                <LeaderboardPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profile">
                <ProfilePage />
              </PrivateRoute>
            </Switch>
          </animated.div>
        ))}
      </Container>

      <Footer />
    </Box>
  );
};

export default App;
