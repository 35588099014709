import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { DayType, Mobility } from '../services/api';
import LabelType from '../types/LabelType';
import Labels from '../services/Labels';
import ScoreType from '../types/ScoreType';

const MobilityScore: Record<Mobility, number> = {
  [Mobility.WALK]: 100,
  [Mobility.BICYCLE]: 100,
  [Mobility.ELECTRIC_BICYCLE]: 90,
  [Mobility.BUS]: 80,
  [Mobility.TRAM]: 80,
  [Mobility.METRO]: 80,
  [Mobility.TRAIN]: 80,
  [Mobility.CARPOOL]: 50,
  [Mobility.ELECTRIC_MOTORCYCLE]: 40,
  [Mobility.ELECTRIC_SCOOTER]: 40,
  [Mobility.ELECTRIC_CAR]: 30,
  [Mobility.MOTORCYCLE]: 20,
  [Mobility.SCOOTER]: 20,
  [Mobility.CAR]: 0,
};

const GroupedMobilityScore = Object.entries(MobilityScore).reduce((grouped, [mobility, value]) => {
  if (grouped[value]) {
    grouped[value].push(Labels.mobility(mobility as Mobility, LabelType.EMOJI_TEXT));

    return grouped;
  }

  return { ...grouped, [value]: [Labels.mobility(mobility as Mobility, LabelType.EMOJI_TEXT)] };
}, {} as Record<number, string[]>);
GroupedMobilityScore[100].push(Labels.dayType(DayType.HOME_OFFICE, LabelType.EMOJI_TEXT));

const ExerciseKph = {
  [Mobility.WALK]: 4.5,
  [Mobility.BICYCLE]: 17.5,
  [Mobility.ELECTRIC_BICYCLE]: 22.5,
};

const useStyles = makeStyles((theme) => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  example: {
    marginTop: theme.spacing(2),
  },
}));

const ScoringHelp = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.section}>
        Deine Punkte werden über deinen Heimweg und Arbeitsweg des Tages zusammen berechnet. Du kannst pro Tag jeweils
        maximal 100 Punkte für deine {Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI_TEXT)} und maximal 100 Punkte
        deine {Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI_TEXT)} kassieren.
      </Typography>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          {Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI_TEXT)}
        </Typography>

        <Grid container spacing={2}>
          <Grid item md>
            <Typography>
              Deine Punkte werden über deinen Heimweg und Arbeitsweg des Tages zusammen berechnet. Legst du die gesamte
              Strecke mit einer Mobilität zurück, erhältst du die Punkte wie in der Tabelle angegeben. Nutzt du
              verschiedene Mobilitätsmodalitäten, werden die Punkte anteilig zur Gesamtdistanz berechnet. Am Ende runden
              wir das Ergebnis damit&apos;s nicht zu kompliziert wird.
            </Typography>
          </Grid>
          <Grid item md>
            <TableContainer>
              <Table aria-label={Labels.scoreType(ScoreType.MOBILITY, LabelType.TEXT)} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Mobilität</TableCell>
                    <TableCell align="right">Punkte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(GroupedMobilityScore).map(([value, mobilities]) => (
                    <TableRow key={value}>
                      <TableCell component="th" scope="row">
                        {mobilities.join(', ')}
                      </TableCell>
                      <TableCell align="right">
                        <strong>{value}</strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Alert severity="info" className={classes.example}>
          <AlertTitle>Beispiel</AlertTitle>
          Nutzt du auf deiner Strecke 50 % das Auto und gehst 50 % zu Fuß erhältst du{' '}
          {MobilityScore[Mobility.CAR] * 0.5 + MobilityScore[Mobility.WALK] * 0.5} Punkte für{' '}
          {Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI_TEXT)}.
        </Alert>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          {Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI_TEXT)}
        </Typography>

        <Grid container spacing={2}>
          <Grid item md>
            <Typography>
              Die WHO empfiehlt 150 bis 300 Minuten Bewegung mit mittlerer Intensität pro Woche. Das kann beispielsweise
              zügiges zu Fuß Gehen oder Fahrradfahren sein. Deshalb kriegst du 100 Punkte, wenn du es schaffst am Tag 60
              Minuten aktive Mobilität auf deinem Weg zu integrieren. Zur Berechnung deiner Bewegungsminuten nutzen wir
              die durchschnittlichen Geschwindigkeiten wie in der Tabelle angegeben.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <TableContainer>
              <Table aria-label={Labels.scoreType(ScoreType.MOBILITY, LabelType.TEXT)} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Mobilität</TableCell>
                    <TableCell align="right">Geschwindigkeit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(ExerciseKph).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {Labels.mobility(key as Mobility, LabelType.EMOJI_TEXT)}
                      </TableCell>
                      <TableCell align="right">
                        <strong>{value.toLocaleString()} km/h</strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Alert severity="info" className={classes.example}>
          <AlertTitle>Beispiel</AlertTitle>
          Fährst du {(ExerciseKph[Mobility.BICYCLE] * 0.5).toLocaleString()} km mit dem Fahrrad entspricht das bei den
          angenommenen {ExerciseKph[Mobility.BICYCLE].toLocaleString()} km/h exakt 30 Minuten Bewegungsdauer und du
          bekommst somit 50 Punkte für {Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI_TEXT)}.
        </Alert>
      </Box>
    </>
  );
};

export default ScoringHelp;
