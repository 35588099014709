import React from 'react';

import useTitle from '../hooks/useTitle';
import Profile from '../containers/Profile';

const ProfilePage = () => {
  useTitle('Profil');

  return <Profile />;
};

export default ProfilePage;
