import React from 'react';
import { Cancel } from '@material-ui/icons';

import { Trip, TripService } from '../services/api';
import AlertDialog from '../components/AlertDialog';

type DeleteTripDialogProps = {
  trip?: Trip;
  onClose: () => void;
};

const DeleteTripDialog = ({ trip, onClose }: DeleteTripDialogProps) => (
  <AlertDialog
    id="delete-trip-dialog"
    open={!!trip?.id}
    title="Vorlage löschen"
    description={
      <>
        Willst du die Vorlage <strong>{trip?.name}</strong> wirklich löschen?
      </>
    }
    onClose={onClose}
    onSubmit={async () => {
      if (trip?.id) {
        await TripService.remove({ id: trip.id });
      }

      onClose();
    }}
    submitIcon={<Cancel />}
    submitLabel="Löschen"
  />
);

export default DeleteTripDialog;
