import useSWR from 'swr';
import { Fetcher } from 'swr/dist/types';
import { useStateMachine } from 'little-state-machine';
import { useEffect } from 'react';

import { updateUser } from '../setup/global-state';

const useApi = <Data>(key: string, fetcher: Fetcher<Data>) => {
  const { actions } = useStateMachine({ updateUser });
  const swrResponse = useSWR(key, fetcher);

  useEffect(() => {
    if (swrResponse?.error?.status === 401) {
      actions.updateUser(undefined);
    }
  }, [actions, swrResponse?.error?.status]);

  return swrResponse;
};

export default useApi;
