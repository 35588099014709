import React from 'react';
import { Box, Link, List, ListItem, makeStyles, Typography } from '@material-ui/core';

import useTitle from '../hooks/useTitle';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
}));

const PrivacyPage = () => {
  useTitle('Datenschutzerklärung');
  const classes = useStyles();

  return (
    <>
      <Box className={classes.section}>
        <Typography variant="h1" gutterBottom>
          Datenschutzerklärung zur Erhebung personenbezogener Daten in einer Studie
        </Typography>

        <Typography gutterBottom>
          Im Rahmen dieser wissenschaftlichen Arbeit werden personenbezogene Daten erhoben und verarbeitet, deshalb
          finden Sie im Folgenden alle Informationen, zu deren Mitteilung ich als Verantwortlicher gemäß der
          Datenschutz-Grundverordnung (DSGVO) verpflichtet bin.
          <br />
          <br />
          Ihre Teilnahme an Studie in Form der Verwendung dieser Plattform erfolgt freiwillig und unentgeltlich. Sie
          können jederzeit ohne Angaben von Gründen ausscheiden. Ist die Datenverarbeitung nicht gewünscht, kann jedoch
          keine Teilnahme an der Studie erfolgen.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          1. Verantwortlicher für die Datenverarbeitung
        </Typography>
        <Typography gutterBottom>
          Durchführender und Verfasser der Masterarbeit und Verantwortlicher für die Datenverarbeitung iSd Art 4 Z 7
          DSGVO ist:
          <br />
          Kilian Finger
          <br />
          Halleiner Landesstraße 64
          <br />
          5412 Puch bei Hallein
          <br />
          Österreich
          <br />
          <br />
          <Link href="mailto:finger@trafficon.eu">finger@trafficon.eu</Link>
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          2. Thema der Masterarbeit
        </Typography>
        <Typography gutterBottom>
          Vorläufiger Titel der wissenschaftlichen Arbeit: Einsatz von persuasiven Technologien zur Förderung von
          nachhaltiger Mobilität im betrieblichen Mobilitätsmanagement
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          3. Welche Daten werden erhoben bzw. verarbeitet?
        </Typography>
        <Typography gutterBottom>
          Alle im Rahmen der Studie angegebenen Daten werden für die wissenschaftliche Arbeit weiterverarbeitet. Die
          Erhebung der Daten erfolgt ausschließlich bei Ihnen.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          4. Rechtsgrundlage
        </Typography>
        <Typography gutterBottom>
          Die Rechtsgrundlage zur Verarbeitung dieser personenbezogenen Daten stellt Ihre Einwilligung nach Art. 6 Abs 1
          lit a bzw. Art. 9 Abs 2 lit a DSGVO bei besonderen Kategorien personenbezogener Daten dar. Sie haben das Recht
          Ihre Einwilligung jederzeit zu widerrufen, was jedoch nicht die Rechtmäßigkeit der bis dahin erfolgten
          Verarbeitung berührt.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          5. Übermittlungsempfänger
        </Typography>
        <Typography gutterBottom>
          An folgende EmpfängerInnen werden Ihre personenbezogenen Daten übermittelt:
          <br />
          <br />
          Fachhochschule Salzburg GmbH
          <br />
          Urstein Süd 1<br />
          5412 Puch bei Hallein
          <br />
          Österreich
          <br />
          <br />
          <br />
          Trafficon – Traffic Consultants GmbH
          <br />
          Strubergasse 26
          <br />
          5020 Salzburg
          <br />
          Österreich
          <br />
          (Datenverarbeiter, ausschließlich zum Betrieb dieser Plattform) <br />
          <br />
          <br />
          LimeSurvey GmbH
          <br />
          Papenreye 63
          <br />
          22453 Hamburg
          <br />
          Deutschland
          <br />
          (Datenverarbeiter, ausschließlich zur Durchführung der Start- und Endumfrage)
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          6. Veröffentlichung und etwaige Publikationen
        </Typography>
        <Typography gutterBottom>
          Gemäß § 19 Abs 3 FHG sind positiv beurteilte Master- und Diplomarbeiten durch Übergabe an die Bibliothek der
          Fachhochschule zu veröffentlichen.
          <br />
          <br />
          Die Abschlussarbeit enthält lediglich anonymisierte Daten dieser Studie, wodurch bei einer Veröffentlichung
          oder Publikation jedenfalls keine Rückschlüsse auf einzelne Personen möglich sind.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          7. Speicherdauer
        </Typography>
        <Typography gutterBottom>
          Sofern Ihre personenbezogenen Daten nicht nach der Erhebung anonymisiert werden, werden sie so lange
          gespeichert, wie sie zur Erfüllung des vorgesehenen Zwecks notwendig sind oder gesetzlich vorgesehen
          Aufbewahrungsfristen bestehen. Zum Nachweis der Einhaltung guter wissenschaftlicher Praxis können
          Forschungsdaten 10 Jahre aufbewahrt werden.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          8. Betroffenenrechte
        </Typography>
        <Typography gutterBottom>
          Ihnen stehen in Bezug auf ihre personenbezogenen Daten folgende Rechte zu:
          <List>
            <ListItem>
              - Sie können Ihre Einwilligung jederzeit bei der oben angeführten Kontaktperson widerrufen.
            </ListItem>
            <ListItem>
              - Recht auf Auskunft, Berechtigung oder Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit,
              welche Sie durch Kontaktaufnahme mit der oben angeführten Kontaktperson geltend machen können.
            </ListItem>
            <ListItem>
              - Wenn Sie der Ansicht sind, dass Ihre personenbezogenen Daten nicht im Einklang mit dem bestehenden
              Datenschutzrecht verarbeitet werden, steht Ihnen weiters das Recht auf Beschwerde bei der österreichischen
              Datenschutzbehörde zu (Barichgasse 40-42, 1030 Wien, Telefon: +43 1 52 152-0, E-Mail: dsb@dsb.gv.at).
            </ListItem>
          </List>
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h3" gutterBottom>
          9. Einwilligung
        </Typography>
        <Typography gutterBottom>
          Mit der Verwendung der Plattform willige ich ein, dass die im Rahmen der Masterarbeit erhobenen
          personenbezogenen Daten durch die verantwortliche Person für die genannten wissenschaftlichen Zwecke in der
          beschriebenen Art und Weise verarbeitet werden dürfen. Sofern ich besondere Kategorien von personenbezogenen
          Daten angegeben habe, sind diese von der Einwilligung ausdrücklich umfasst.
        </Typography>
      </Box>
    </>
  );
};

export default PrivacyPage;
