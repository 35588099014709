import React, { ReactNode } from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
  },
}));

type NivoTooltipProps = { children: ReactNode };

const NivoTooltip = ({ children }: NivoTooltipProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={6} className={classes.tooltip}>
      {children}
    </Paper>
  );
};

export default NivoTooltip;
