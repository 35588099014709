/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { Add } from '@material-ui/icons';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';

import { Mobility, TripDirection, TripSegmentDto } from '../services/api';

import TripPieChart from './TripPieChart';
import TripFormRow from './TripFormRow';
import TripWrapperSingle from './TripWrapperSingle';

const useStyles = makeStyles((theme) => ({
  tripWrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(40),
  },
  addButton: {
    width: '100%',
    maxWidth: theme.spacing(25),
    marginTop: theme.spacing(4),
  },
  dropzone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    transition: 'background-color ease 300ms',
  },
  dropzoneActive: {
    backgroundColor: theme.palette.grey[100],
  },
  pieChart: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type TripFormProps = {
  direction: TripDirection;
  fieldArray: UseFieldArrayReturn<{ segments: TripSegmentDto[] }>;
};

const TripForm = ({ direction, fieldArray }: TripFormProps) => {
  const classes = useStyles();
  const { watch } = useFormContext<{ segments: TripSegmentDto[] }>();
  const { fields, append, remove, move } = fieldArray;
  const segments = watch('segments');

  const handleDrag = ({ source, destination }: DropResult) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <TripWrapperSingle direction={direction} dataTour="trip-form">
      <Grid container spacing={4}>
        <Grid item xs>
          <Box className={classes.tripWrapperInner}>
            <DragDropContext onDragEnd={handleDrag}>
              <Droppable droppableId="segments">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={clsx(classes.dropzone, snapshot.isDraggingOver && classes.dropzoneActive)}
                  >
                    {fields.map((field, index) => (
                      <TripFormRow key={field.id} field={field} index={index} onRemove={() => remove(index)} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              className={classes.addButton}
              endIcon={<Add />}
              color="secondary"
              variant="contained"
              disableElevation
              onClick={() => append({ mobility: Mobility.WALK, distance: 0 })}
            >
              Hinzufügen
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} lg={5} className={classes.pieChart}>
          <TripPieChart segments={segments} />
        </Grid>
      </Grid>
    </TripWrapperSingle>
  );
};

export default TripForm;
