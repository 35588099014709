import React from 'react';
import { Typography } from '@material-ui/core';
import { useStateMachine } from 'little-state-machine';

import WeekOverview from '../containers/WeekOverview';
import useTitle from '../hooks/useTitle';
import PageTour from '../components/PageTour';
import HelpButton from '../components/HelpButton';
import YearOverview from '../containers/YearOverview';
import EndAlert from '../components/EndAlert';

const HomePage = () => {
  useTitle();
  const {
    state: { user },
  } = useStateMachine({});

  return (
    <>
      <HelpButton />

      <EndAlert />

      <WeekOverview />

      <YearOverview />

      <PageTour
        steps={[
          {
            content: (
              <>
                <Typography variant="h4">Hey {user?.displayName}!</Typography>
                <Typography>
                  Bei &quot;hin und her&quot; geht&apos;s darum möglichst nachhaltig und aktiv zur Arbeit zu kommen. Für
                  jeden Weg zur Arbeit kannst du Punkte für Nachhaltigkeit 🌱 und Bewegung 💪 kassieren und damit Dein
                  Team im Wettkampf unterstützen!
                </Typography>
              </>
            ),
          },

          {
            selector: '[data-tour="week-overview-days"]',
            content: (
              <Typography>
                Hier siehst du deine aktuelle Woche. Du hast bis zu eine Woche Zeit einen Arbeitstag einzutragen.
              </Typography>
            ),
          },
          {
            selector: '[data-tour="date-navigation"]',
            content: (
              <Typography>
                Navigiere zwischen den Wochen, über den Kalender kommst du immer zur aktuellen Woche.
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};

export default HomePage;
