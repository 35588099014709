import React, { FC, useState } from 'react';
import { AppBar, Box, Button, Drawer, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { useStateMachine } from 'little-state-machine';
import { AccountCircle, Equalizer, ExitToApp, Home, Menu } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { updateUser } from '../setup/global-state';
import { AuthService } from '../services/api';

import NavigationLink from './NavigationLink';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: 'inherit',
  },
  title: {
    position: 'relative',
    textDecoration: 'none',
    color: 'inherit',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: theme.spacing(0.25),
      right: 0,
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.25),
      transition: 'background-color ease 300ms',
    },

    '&:hover::after': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  items: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logout: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),

    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
    },
  },
  drawer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Navigation: FC = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {
    state: { user },
    actions,
  } = useStateMachine({ updateUser });

  const Links = (
    <>
      {[
        { title: 'Home', to: '/', icon: <Home /> },
        { title: 'Wettkampf', to: '/leaderboards', icon: <Equalizer /> },
        { title: 'Profil', to: '/profile', icon: <AccountCircle /> },
      ].map(({ title, to, icon }) => (
        <NavigationLink key={to} title={title} to={to} icon={icon} onClick={() => setDrawerOpen(false)} />
      ))}

      <Button
        className={classes.logout}
        startIcon={<ExitToApp />}
        onClick={async () => {
          await AuthService.logout();
          actions.updateUser(undefined);
        }}
      >
        Logout
      </Button>
    </>
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h1" component={Link} to="/">
            hin und her
          </Typography>

          {user && (
            <Box className={classes.items}>
              <Hidden smDown implementation="css">
                {Links}
              </Hidden>

              <Hidden mdUp implementation="css">
                <IconButton onClick={() => setDrawerOpen(true)} size="small">
                  <Menu />
                </IconButton>
              </Hidden>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {user && (
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen((prevState) => !prevState)}
            ModalProps={{
              keepMounted: true,
            }}
            classes={{ paper: classes.drawer }}
          >
            {Links}
          </Drawer>
        </Hidden>
      )}
    </>
  );
};

export default Navigation;
