import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import ScoringHelp from './ScoringHelp';

type AlertDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AlertDialog = ({ open, onClose }: AlertDialogProps) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="scoring-help-dialog-title" maxWidth="md" fullWidth>
    <DialogTitle id="scoring-help-dialog-title" disableTypography>
      <Typography variant="h3">Wie funktioniert das Punktesystem?</Typography>
    </DialogTitle>

    <DialogContent>
      <ScoringHelp />
    </DialogContent>
  </Dialog>
);

export default AlertDialog;
