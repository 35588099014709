import React from 'react';
import BoringAvatar from 'boring-avatars';

import theme from '../setup/theme';

type AvatarProps = {
  name: string;
  size?: number;
  variant: 'user' | 'team';
};

const Avatar = ({ name, size, variant }: AvatarProps) => (
  <BoringAvatar
    name={name}
    size={size}
    variant={({ user: 'beam', team: 'bauhaus' } as const)[variant]}
    colors={[
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.tertiary.main,
      theme.palette.quaternary.main,
    ]}
    // @ts-ignore
    style={{ display: 'block' }}
  />
);

export default Avatar;
