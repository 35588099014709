import React from 'react';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { Box, Button, makeStyles } from '@material-ui/core';

import { Day, DayStep } from '../services/api';
import DayHelper from '../services/DayHelper';

import ScoreCircles from './ScoreCircles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),

    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.up('md')]: {
        height: '100%',
        flexDirection: 'column',
      },
    },
  },
  dayOff: {
    fontSize: '1.5rem',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
  shortDay: {
    fontSize: '3rem',
    textAlign: 'center',
    marginLeft: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
  },
}));

type WeekOverviewDayProps = {
  date: Dayjs;
  day?: Day;
};

const WeekOverviewDay = ({ date, day }: WeekOverviewDayProps) => {
  const classes = useStyles();
  const shortDayString = date.format('dd');

  return (
    <Button
      className={classes.root}
      disabled={!DayHelper.isEditable(date) && day?.step !== DayStep.SUBMITTED}
      component={Link}
      to={`/days/${date.format('YYYY-MM-DD')}`}
    >
      <ScoreCircles day={day} date={date} disabled={!DayHelper.isSameOrBeforeToday(date)} />

      <Box className={classes.shortDay}>{shortDayString}</Box>
    </Button>
  );
};

export default WeekOverviewDay;
