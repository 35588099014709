import React from 'react';
import { Typography } from '@material-ui/core';

import useTitle from '../hooks/useTitle';
import Leaderboard from '../containers/Leaderboard';
import PageTour from '../components/PageTour';
import HelpButton from '../components/HelpButton';

const LeaderboardPage = () => {
  useTitle('Wettkampf');

  return (
    <>
      <HelpButton />

      <Leaderboard />

      <PageTour
        steps={[
          {
            content: (
              <>
                <Typography variant="h4">Wie funktioniert der Wettkampf?</Typography>
                <Typography>
                  Hier kannst du sehen, wie du und dein Team im Vergleich zu den anderen abschneidest!
                </Typography>
              </>
            ),
          },
          {
            selector: '[data-tour="leaderboard-filter-aggregation"]',
            content: (
              <Typography>
                In der Teams Rangliste siehst du wie dein Team innerhalb eurer Organisation abschneidet. Unter Mein Team
                siehst du nur deine Teammitglieder, unter Meine Organisation alle anderen.
              </Typography>
            ),
          },
          {
            selector: '[data-tour="leaderboard-filter-sort"]',
            content: <Typography>Hier kannst du nach Nachhaltigkeit 🌱 oder Bewegung 💪 sortieren lassen.</Typography>,
          },
        ]}
      />
    </>
  );
};

export default LeaderboardPage;
