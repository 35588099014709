import React from 'react';
import { Box, Button, makeStyles, Tooltip } from '@material-ui/core';
import { CalendarToday, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  centerButton: {
    borderRadius: 0,
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

type DateNavigationProps = {
  previousTitle: string;
  currentTitle: string;
  nextTitle: string;
  onPrevious?: () => void;
  onCurrent?: () => void;
  onNext?: () => void;
  toPrevious?: string;
  toCurrent?: string;
  toNext?: string;
  disabledPrevious?: boolean;
  disabledNext?: boolean;
};

const DateNavigation = ({
  onPrevious,
  onCurrent,
  onNext,
  toPrevious,
  toCurrent,
  toNext,
  previousTitle,
  currentTitle,
  nextTitle,
  disabledPrevious,
  disabledNext,
}: DateNavigationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-tour="date-navigation">
      <Tooltip title={!disabledPrevious && previousTitle} aria-label={previousTitle}>
        <Box>
          {onPrevious && (
            <Button className={classes.leftButton} onClick={onPrevious} disabled={disabledPrevious}>
              <ChevronLeft />
            </Button>
          )}
          {toPrevious && (
            <Button className={classes.leftButton} component={Link} to={toPrevious} disabled={disabledPrevious}>
              <ChevronLeft />
            </Button>
          )}
        </Box>
      </Tooltip>

      <Tooltip title={currentTitle} aria-label={currentTitle}>
        <Box>
          {onCurrent && (
            <Button className={classes.centerButton} onClick={onCurrent}>
              <CalendarToday />
            </Button>
          )}
          {toCurrent && (
            <Button className={classes.centerButton} component={Link} to={toCurrent}>
              <CalendarToday />
            </Button>
          )}
        </Box>
      </Tooltip>

      <Tooltip title={disabledNext ? '' : nextTitle} aria-label={nextTitle}>
        <Box>
          {onNext && (
            <Button className={classes.rightButton} onClick={onNext} disabled={disabledNext}>
              <ChevronRight />
            </Button>
          )}
          {toNext && (
            <Button className={classes.rightButton} component={Link} to={toNext} disabled={disabledNext}>
              <ChevronRight />
            </Button>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default DateNavigation;
