/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDayDto } from '../models/CreateDayDto';
import type { Day } from '../models/Day';
import type { UpdateDayDto } from '../models/UpdateDayDto';
import { request as __request } from '../core/request';

export class DayService {

    /**
     * @returns Day
     * @throws ApiError
     */
    public static async create({
        requestBody,
    }: {
        requestBody: CreateDayDto,
    }): Promise<Day> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/days`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns Day
     * @throws ApiError
     */
    public static async findAllForUser({
        startDate,
        endDate,
    }: {
        startDate?: string,
        endDate?: string,
    }): Promise<Array<Day>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/days`,
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
        return result.body;
    }

    /**
     * @returns Day
     * @throws ApiError
     */
    public static async findOne({
        id,
    }: {
        id: string,
    }): Promise<Day> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/days/${id}`,
        });
        return result.body;
    }

    /**
     * @returns Day
     * @throws ApiError
     */
    public static async update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateDayDto,
    }): Promise<Day> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/days/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async remove({
        id,
    }: {
        id: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/days/${id}`,
        });
        return result.body;
    }

}