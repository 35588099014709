import React, { ReactNode } from 'react';
import {
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import { TeamScoreDto, UserScoreDto } from '../services/api';
import { LeaderboardAggregation } from '../types/LeaderboardFilterSettings';
import LabelType from '../types/LabelType';
import Labels from '../services/Labels';
import ScoreType from '../types/ScoreType';

import Avatar from './Avatar';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-root': {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  shrinkColumn: {
    width: theme.spacing(8),
  },
  boldColumn: {
    fontWeight: 'bold',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
}));

type LeaderboardTableProps<T> = {
  scores: T[];
  ownId?: string;
  getScoreId: (scoreDto: T) => string;
  getScoreAvatar: (scoreDto: T) => string;
  getScoreName: (scoreDto: T) => string | ReactNode;
  aggregation: LeaderboardAggregation;
};

const LeaderboardTable = <T extends UserScoreDto | TeamScoreDto>({
  scores,
  ownId,
  getScoreId,
  getScoreAvatar,
  getScoreName,
  aggregation,
}: LeaderboardTableProps<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer>
      <Table className={classes.table} size={matches ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Platz</TableCell>
            <TableCell colSpan={2}>Teammitglied</TableCell>
            <TableCell align="right">Nachhaltigkeit</TableCell>
            <TableCell align="right">Bewegung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scores.map((score, index) => {
            const id = getScoreId(score);

            return (
              <TableRow key={id}>
                <TableCell className={clsx(classes.shrinkColumn, classes.boldColumn)}>{index + 1}.</TableCell>
                <TableCell className={classes.shrinkColumn}>
                  <Avatar
                    name={getScoreAvatar(score)}
                    variant={
                      (
                        {
                          [LeaderboardAggregation.TEAM_USERS]: 'user',
                          [LeaderboardAggregation.ORGANIZATION_TEAMS]: 'team',
                          [LeaderboardAggregation.ORGANIZATION_USERS]: 'user',
                        } as const
                      )[aggregation]
                    }
                    size={64}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {getScoreName(score)}

                  {aggregation === LeaderboardAggregation.ORGANIZATION_USERS && (score as UserScoreDto).user.team && (
                    <Chip className={classes.chip} label={(score as UserScoreDto).user.team?.name} />
                  )}

                  {id === ownId && (
                    <Chip
                      color="primary"
                      className={classes.chip}
                      label={
                        {
                          [LeaderboardAggregation.TEAM_USERS]: 'Ich',
                          [LeaderboardAggregation.ORGANIZATION_TEAMS]: 'Mein Team',
                          [LeaderboardAggregation.ORGANIZATION_USERS]: 'Ich',
                        }[aggregation]
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="right" className={clsx(classes.shrinkColumn, classes.boldColumn)}>
                  {score.score.mobility} {Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI)}
                </TableCell>
                <TableCell align="right" className={clsx(classes.shrinkColumn, classes.boldColumn)}>
                  {score.score.exercise} {Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderboardTable;
