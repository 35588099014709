import React, { useState } from 'react';
import { Button, Grid, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { Bookmark } from '@material-ui/icons';

import { Day, DayStep, DayType, Trip, TripDirection } from '../services/api';
import Labels from '../services/Labels';
import TripDestination from '../types/TripDestination';
import LabelType from '../types/LabelType';
import CreateTripDialog from '../containers/CreateTripDialog';

import TripWrapperPlace from './TripWrapperPlace';
import TripWrapperLine from './TripWrapperLine';
import TripView from './TripView';
import ScoreCircles from './ScoreCircles';
import SubmitButtons from './SubmitButtons';
import PageTour from './PageTour';
import DayBadges from './DayBadges';

const useStyles = makeStyles((theme) => ({
  scoreCircles: {
    maxWidth: theme.spacing(40),
    marginRight: 'auto',
    marginLeft: 'auto',

    [theme.breakpoints.down('xs')]: {
      order: -1,
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

type TripReviewProps = { day: Day };

const TripReview = ({ day }: TripReviewProps) => {
  const classes = useStyles();
  const [createTrip, setCreateTrip] = useState<Pick<Trip, 'direction' | 'segments'> | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

  const handleClose = (trip: Pick<Trip, 'direction' | 'segments'> | undefined) => {
    setCreateTrip(trip);
    setAnchorEl(undefined);
  };

  return (
    <>
      <Grid container alignItems="center" data-tour="trip-review">
        {day.type === DayType.COMMUTED && (
          <Grid item>
            <TripWrapperPlace
              emoji={Labels.tripDestination(TripDestination.HOME, LabelType.EMOJI)}
              title={Labels.tripDestination(TripDestination.HOME, LabelType.TEXT)}
            />

            <TripWrapperLine>
              <TripView segments={day.tripToWork} />
            </TripWrapperLine>

            <TripWrapperPlace
              emoji={Labels.tripDestination(TripDestination.WORK, LabelType.EMOJI)}
              title={Labels.tripDestination(TripDestination.WORK, LabelType.TEXT)}
            />

            <TripWrapperLine>
              <TripView segments={day.tripToHome} />
            </TripWrapperLine>

            <TripWrapperPlace
              emoji={Labels.tripDestination(TripDestination.HOME, LabelType.EMOJI)}
              title={Labels.tripDestination(TripDestination.HOME, LabelType.TEXT)}
            />
          </Grid>
        )}

        {(day.step === DayStep.SCORE || day.step === DayStep.SUBMITTED) && (
          <Grid item xs={12} sm className={classes.scoreCircles}>
            <ScoreCircles width="100%" day={day} date={dayjs(day.date)} scorePreview scoringHelp />
          </Grid>
        )}
      </Grid>

      {day.step === DayStep.SUBMITTED && day.type === DayType.COMMUTED && (
        <>
          <SubmitButtons
            left={
              <>
                <Button
                  endIcon={<Bookmark />}
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  Vorlage speichern
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => handleClose(undefined)}>
                  <MenuItem onClick={() => handleClose({ direction: TripDirection.TO_WORK, segments: day.tripToWork })}>
                    {Labels.tripDirection(TripDirection.TO_WORK, LabelType.EMOJI_TEXT)}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose({ direction: TripDirection.TO_HOME, segments: day.tripToHome })}>
                    {Labels.tripDirection(TripDirection.TO_HOME, LabelType.EMOJI_TEXT)}
                  </MenuItem>
                </Menu>
              </>
            }
          />

          <DayBadges day={day} />

          <CreateTripDialog trip={createTrip} onClose={() => setCreateTrip(undefined)} />
        </>
      )}

      {day.step === DayStep.SUBMITTED && (
        <PageTour
          steps={[
            {
              selector: '[data-tour="trip-review"]',
              content: <Typography>Dieser Tag ist abgeschlossen, du kannst ihn nicht mehr bearbeiten.</Typography>,
            },
          ]}
        />
      )}
    </>
  );
};

export default TripReview;
