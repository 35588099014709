import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';

import { TripDirection } from '../services/api';
import Labels from '../services/Labels';
import TripDestination from '../types/TripDestination';
import LabelType from '../types/LabelType';

import TripWrapperPlace from './TripWrapperPlace';
import TripWrapperLine from './TripWrapperLine';

type TripWrapperSingleProps = {
  children?: ReactNode;
  direction: TripDirection;
  dataTour?: string;
};

const TripWrapperSingle = ({ direction, children, dataTour }: TripWrapperSingleProps) => (
  <Box data-tour={dataTour}>
    <TripWrapperPlace
      emoji={Labels.tripDestination(
        direction === TripDirection.TO_WORK ? TripDestination.HOME : TripDestination.WORK,
        LabelType.EMOJI,
      )}
      title={Labels.tripDirection(direction, LabelType.TEXT)}
    />

    <TripWrapperLine>{children}</TripWrapperLine>

    <TripWrapperPlace
      emoji={Labels.tripDestination(
        direction === TripDirection.TO_HOME ? TripDestination.HOME : TripDestination.WORK,
        LabelType.EMOJI,
      )}
      title={Labels.tripDestination(
        direction === TripDirection.TO_HOME ? TripDestination.HOME : TripDestination.WORK,
        LabelType.TEXT,
      )}
    />
  </Box>
);

export default TripWrapperSingle;
