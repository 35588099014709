import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    width: '100%',
    padding: theme.spacing(2),
    border: `${theme.spacing(0.5)}px solid ${theme.palette.grey[300]}`,

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      height: theme.spacing(40),
      width: theme.spacing(30),
      padding: theme.spacing(4),

      '&:not(:last-child)': {
        marginBottom: 0,
        marginRight: theme.spacing(2),
      },
    },
  },
  typeEmojis: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  typeLabel: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selected: {
    position: 'absolute',

    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(2),
    },
  },
}));

type DayTypeSelectItemProps = { emojis: string; label: string; selected: boolean; onClick: () => void };

const DayTypeSelectItem = ({ emojis, label, selected, onClick }: DayTypeSelectItemProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.item} onClick={onClick}>
      <Box className={classes.typeEmojis}>{emojis}</Box>

      <Typography variant="h3" className={classes.typeLabel}>
        {label}
      </Typography>

      {selected ? (
        <RadioButtonChecked className={classes.selected} color="primary" fontSize="large" />
      ) : (
        <RadioButtonUnchecked className={classes.selected} color="primary" fontSize="large" />
      )}
    </Button>
  );
};

export default DayTypeSelectItem;
