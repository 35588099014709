import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import PaperContainer from './PaperContainer';

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const EndAlert = () => {
  const classes = useStyles();

  return (
    <PaperContainer className={classes.root}>
      <Alert severity="info">
        <AlertTitle>Ende der Feldstudie</AlertTitle>
        Die Feldstudie ist beendet. Es können keine weiteren Tage eingetragen werden.
      </Alert>
    </PaperContainer>
  );
};

export default EndAlert;
