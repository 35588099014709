/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTripDto } from '../models/CreateTripDto';
import type { Trip } from '../models/Trip';
import type { UpdateTripDto } from '../models/UpdateTripDto';
import { request as __request } from '../core/request';

export class TripService {

    /**
     * @returns Trip
     * @throws ApiError
     */
    public static async create({
        requestBody,
    }: {
        requestBody: CreateTripDto,
    }): Promise<Trip> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/trips`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns Trip
     * @throws ApiError
     */
    public static async findAllForUser(): Promise<Array<Trip>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/trips`,
        });
        return result.body;
    }

    /**
     * @returns Trip
     * @throws ApiError
     */
    public static async findOne({
        id,
    }: {
        id: string,
    }): Promise<Trip> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/trips/${id}`,
        });
        return result.body;
    }

    /**
     * @returns Trip
     * @throws ApiError
     */
    public static async update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateTripDto,
    }): Promise<Trip> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/trips/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async remove({
        id,
    }: {
        id: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/trips/${id}`,
        });
        return result.body;
    }

}