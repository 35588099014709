import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Typography } from '@material-ui/core';

import { Day, DayService, DayStep, DayType, TripDirection } from '../services/api';
import PaperContainer from '../components/PaperContainer';
import ApiError from '../components/ApiError';
import TripWizard from '../components/TripWizard';
import DateNavigation from '../components/DateNavigation';
import DayTypeSelect from '../components/DayTypeSelect';
import DayStepper from '../components/DayStepper';
import useApi from '../hooks/useApi';
import TripSubmit from '../components/TripSubmit';
import TripReview from '../components/TripReview';
import PaperContainerHeader from '../components/PaperContainerHeader';
import HelpButton from '../components/HelpButton';
import DayHelper from '../services/DayHelper';

const getDayStepIndex = (step: DayStep) =>
  [DayStep.DAY_TYPE, DayStep.TRIP_TO_WORK, DayStep.TRIP_TO_HOME, DayStep.SCORE, DayStep.SUBMITTED].indexOf(step);

const DayPage = () => {
  const { date: dateString } = useParams<{ date: string }>();
  const date = dayjs(dateString);

  const {
    data = [],
    error,
    mutate,
    isValidating,
  } = useApi(`/api/v1/days?startDate=${dateString}&endDate=${dateString}`, () =>
    DayService.findAllForUser({ startDate: dateString, endDate: dateString }),
  );

  const day = data[0] ? data[0] : undefined;

  const handleSubmit = async (dayData: Partial<Day>) => {
    await mutate([{ ...(day as Day), ...dayData }], false);
    if (day?.id) {
      await mutate([await DayService.update({ id: day.id, requestBody: dayData })]);
    } else {
      await mutate([
        await DayService.create({
          requestBody: {
            date: dateString,
            step: DayStep.DAY_TYPE,
            type: DayType.COMMUTED,
            tripToWork: [],
            tripToHome: [],
            ...dayData,
          },
        }),
      ]);
    }
  };

  const finishedSteps = useMemo(() => {
    const steps = [];

    if (day?.step) {
      const step = getDayStepIndex(day.step);
      if (step > getDayStepIndex(DayStep.DAY_TYPE)) {
        steps.push(DayStep.DAY_TYPE);
      }

      if (day.type === DayType.COMMUTED) {
        if (step > getDayStepIndex(DayStep.TRIP_TO_WORK)) {
          steps.push(DayStep.TRIP_TO_WORK);
        }
        if (step > getDayStepIndex(DayStep.TRIP_TO_HOME)) {
          steps.push(DayStep.TRIP_TO_HOME);
        }
      }

      if (step > getDayStepIndex(DayStep.SCORE)) {
        steps.push(DayStep.SCORE);
      }
    }

    return steps;
  }, [day?.step, day?.type]);

  if (date.isAfter(dayjs()) || !date.isBusinessDay()) {
    return (
      <Redirect to={`/days/${(dayjs().isBusinessDay() ? dayjs() : dayjs().lastBusinessDay()).format('YYYY-MM-DD')}`} />
    );
  }

  return (
    <>
      {(DayHelper.isEditable(date) || day?.step === DayStep.SUBMITTED) && (
        <DayStepper activeStep={(day?.step && getDayStepIndex(day?.step)) || 0} finishedSteps={finishedSteps} />
      )}
      <ApiError error={!!error} />
      <PaperContainer>
        <PaperContainerHeader
          title={date.format(dayjs().localeData().longDateFormat('L'))}
          subTitle={date.format('dddd')}
        >
          <HelpButton />

          <DateNavigation
            toPrevious={date.lastBusinessDay().format('YYYY-MM-DD')}
            toCurrent={(dayjs().isBusinessDay() ? dayjs() : dayjs().lastBusinessDay()).format('YYYY-MM-DD')}
            toNext={date.nextBusinessDay().format('YYYY-MM-DD')}
            previousTitle="Vorherigen Tag anzeigen"
            currentTitle="Heutigen Tag anzeigen"
            nextTitle="Nächsten Tag anzeigen"
            disabledNext={date.nextBusinessDay().isAfter(dayjs())}
          />
        </PaperContainerHeader>

        {(!isValidating || day !== undefined) &&
          (DayHelper.isEditable(date) && !dayjs().isAfter('2021-09-17') ? (
            <>
              {(day?.step === DayStep.DAY_TYPE || day?.step === undefined) && (
                <DayTypeSelect
                  type={day?.type}
                  onSelect={(type) => {
                    handleSubmit({
                      type,
                      ...([DayType.HOME_OFFICE, DayType.DAY_OFF].includes(type) && { tripToWork: [], tripToHome: [] }),
                    });
                  }}
                  onNext={() => {
                    if (day?.type) {
                      if (day.type === DayType.COMMUTED) {
                        handleSubmit({ step: DayStep.TRIP_TO_WORK });
                      } else {
                        handleSubmit({ step: DayStep.SCORE });
                      }
                    }
                  }}
                />
              )}

              {day?.step === DayStep.TRIP_TO_WORK && (
                <>
                  <TripWizard
                    segments={day?.tripToWork}
                    direction={TripDirection.TO_WORK}
                    onSubmit={(tripToWork) => handleSubmit({ tripToWork })}
                    onBack={() => handleSubmit({ step: DayStep.DAY_TYPE })}
                    onNext={() => handleSubmit({ step: DayStep.TRIP_TO_HOME })}
                  />
                </>
              )}

              {day?.step === DayStep.TRIP_TO_HOME && (
                <TripWizard
                  segments={day.tripToHome}
                  previousSegments={day.tripToWork}
                  direction={TripDirection.TO_HOME}
                  onSubmit={(tripToHome) => handleSubmit({ tripToHome })}
                  onBack={() => handleSubmit({ step: DayStep.TRIP_TO_WORK })}
                  onNext={() => handleSubmit({ step: DayStep.SCORE })}
                />
              )}

              {day?.step === DayStep.SCORE && (
                <TripSubmit
                  day={day}
                  onBack={() =>
                    handleSubmit({ step: day.type === DayType.COMMUTED ? DayStep.TRIP_TO_HOME : DayStep.DAY_TYPE })
                  }
                  onNext={() => handleSubmit({ step: DayStep.SUBMITTED })}
                />
              )}
            </>
          ) : (
            day?.step !== DayStep.SUBMITTED && (
              <Typography variant="h3" color="textSecondary" align="center" style={{ padding: 16 }}>
                Dieser Tag kann nicht mehr editiert werden.
              </Typography>
            )
          ))}

        {day?.step === DayStep.SUBMITTED && <TripReview day={day} />}
      </PaperContainer>
    </>
  );
};

export default DayPage;
