import React, { ReactNode } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 1,
      textAlign: 'center',
    },
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    display: 'block',
  },
  children: {
    [theme.breakpoints.down('sm')]: {
      order: 0,
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
}));

type PaperContainerHeaderProps = { title: ReactNode; subTitle?: string; children?: ReactNode; marginBottom?: boolean };

const PaperContainerHeader = ({ title, subTitle, children, marginBottom = true }: PaperContainerHeaderProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, marginBottom && classes.marginBottom)}>
      <Typography variant="h1" className={classes.heading}>
        {title}
        {subTitle && (
          <>
            <span className={classes.subTitle}>{subTitle}</span>
          </>
        )}
      </Typography>

      {children && <Box className={classes.children}>{children}</Box>}
    </Box>
  );
};

export default PaperContainerHeader;
