import React from 'react';
import { Box, Button, Container, Link, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  item: { color: theme.palette.text.primary, '&:not(:last-child)': { marginRight: theme.spacing(2) } },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.root}>
      <Container>
        <Box className={classes.items}>
          <Button className={classes.item} component={NavLink} to="/privacy">
            Datenschutzerklärung
          </Button>

          <Button className={classes.item} component={Link} href="mailto:finger@trafficon.eu?subject=hin und her">
            Kontakt
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
