import TripDestination from '../types/TripDestination';
import LabelType from '../types/LabelType';
import ScoreType from '../types/ScoreType';

import { DayType, Mobility, TripDirection } from './api';

class Labels {
  public static mobility(mobility: Mobility, type: LabelType): string {
    if (type === LabelType.EMOJI) {
      return {
        [Mobility.WALK]: '🚶',
        [Mobility.BICYCLE]: '🚲',
        [Mobility.ELECTRIC_BICYCLE]: '🚲⚡️',
        [Mobility.BUS]: '🚌',
        [Mobility.TRAM]: '🚋',
        [Mobility.METRO]: '🚇',
        [Mobility.TRAIN]: '🚄',
        [Mobility.SCOOTER]: '🛵',
        [Mobility.ELECTRIC_SCOOTER]: '🛵⚡️',
        [Mobility.MOTORCYCLE]: '🏍',
        [Mobility.ELECTRIC_MOTORCYCLE]: '🏍⚡️',
        [Mobility.CARPOOL]: '👥',
        [Mobility.CAR]: '🚗',
        [Mobility.ELECTRIC_CAR]: '🚗⚡️',
      }[mobility];
    }

    if (type === LabelType.TEXT) {
      return {
        [Mobility.WALK]: 'Zu Fuß',
        [Mobility.BICYCLE]: 'Fahrrad',
        [Mobility.ELECTRIC_BICYCLE]: 'E-Bike',
        [Mobility.BUS]: 'Bus',
        [Mobility.TRAM]: 'Straßenbahn',
        [Mobility.METRO]: 'U-Bahn',
        [Mobility.TRAIN]: 'Zug',
        [Mobility.SCOOTER]: 'Motorroller',
        [Mobility.ELECTRIC_SCOOTER]: 'E-Motorroller',
        [Mobility.MOTORCYCLE]: 'Motorrad',
        [Mobility.ELECTRIC_MOTORCYCLE]: 'E-Motorrad',
        [Mobility.CARPOOL]: 'Fahrgemeinschaft',
        [Mobility.CAR]: 'Auto (allein)',
        [Mobility.ELECTRIC_CAR]: 'E-Auto (allein)',
      }[mobility];
    }

    if (type === LabelType.EMOJI_TEXT) {
      return `${Labels.mobility(mobility, LabelType.EMOJI)} ${Labels.mobility(mobility, LabelType.TEXT)}`;
    }

    return '';
  }

  public static tripDestination(direction: TripDestination, type: LabelType): string {
    if (type === LabelType.EMOJI) {
      return {
        [TripDestination.HOME]: '🏡',
        [TripDestination.WORK]: '🏢',
      }[direction];
    }

    if (type === LabelType.TEXT) {
      return {
        [TripDestination.HOME]: 'Zuhause',
        [TripDestination.WORK]: 'Arbeit',
      }[direction];
    }

    if (type === LabelType.EMOJI_TEXT) {
      return `${Labels.tripDestination(direction, LabelType.EMOJI)} ${Labels.tripDestination(
        direction,
        LabelType.TEXT,
      )}`;
    }

    return '';
  }

  public static tripDirection(direction: TripDirection, type: LabelType): string {
    if (type === LabelType.EMOJI) {
      return {
        [TripDirection.TO_WORK]: '🏡➡️🏢',
        [TripDirection.TO_HOME]: '️🏢➡️🏡',
      }[direction];
    }

    if (type === LabelType.TEXT) {
      return {
        [TripDirection.TO_WORK]: 'Arbeitsweg',
        [TripDirection.TO_HOME]: 'Heimweg',
      }[direction];
    }

    if (type === LabelType.EMOJI_TEXT) {
      return `${Labels.tripDirection(direction, LabelType.EMOJI)} ${Labels.tripDirection(direction, LabelType.TEXT)}`;
    }

    return '';
  }

  public static dayType(dayType: DayType, type: LabelType): string {
    if (type === LabelType.EMOJI) {
      return {
        [DayType.COMMUTED]: '🏡 ↔️ 🏢',
        [DayType.HOME_OFFICE]: '🏡',
        [DayType.DAY_OFF]: '🆓 📆 🤧',
      }[dayType];
    }

    if (type === LabelType.TEXT) {
      return {
        [DayType.COMMUTED]: 'Gependelt',
        [DayType.HOME_OFFICE]: 'Homeoffice',
        [DayType.DAY_OFF]: 'Abwesend',
      }[dayType];
    }

    if (type === LabelType.EMOJI_TEXT) {
      return `${Labels.dayType(dayType, LabelType.EMOJI)} ${Labels.dayType(dayType, LabelType.TEXT)}`;
    }

    return '';
  }

  public static scoreType(scoreType: ScoreType, type: LabelType): string {
    if (type === LabelType.EMOJI) {
      return {
        [ScoreType.MOBILITY]: '🌱',
        [ScoreType.EXERCISE]: '💪',
      }[scoreType];
    }

    if (type === LabelType.TEXT) {
      return {
        [ScoreType.MOBILITY]: 'Nachhaltigkeit',
        [ScoreType.EXERCISE]: 'Bewegung',
      }[scoreType];
    }

    if (type === LabelType.EMOJI_TEXT) {
      return `${Labels.scoreType(scoreType, LabelType.EMOJI)} ${Labels.scoreType(scoreType, LabelType.TEXT)}`;
    }

    return '';
  }
}

export default Labels;
