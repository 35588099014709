import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

type AlertDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  onSubmit?: () => void;
  submitIcon: ReactNode;
  submitLabel: string;
};

const AlertDialog = ({
  id,
  open,
  onClose,
  maxWidth,
  title,
  description,
  children,
  onSubmit,
  submitIcon,
  submitLabel,
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={`${id}-title`}
    aria-describedby={`${id}-description`}
    maxWidth={maxWidth}
  >
    <DialogTitle id={`${id}-title`} disableTypography>
      <Typography variant="h3">{title}</Typography>
    </DialogTitle>
    <DialogContent>
      {description && <DialogContentText id={`${id}-description`}>{description}</DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="default" variant="contained" disableElevation>
        Abbrechen
      </Button>
      <Button
        onClick={onSubmit}
        form={onSubmit ? undefined : `${id}-form`}
        type={onSubmit ? 'button' : 'submit'}
        color="primary"
        variant="contained"
        disableElevation
        autoFocus
        endIcon={submitIcon}
      >
        {submitLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
