import React from 'react';
import dayjs from 'dayjs';
import { Box, makeStyles } from '@material-ui/core';

import useApi from '../hooks/useApi';
import { DayService } from '../services/api';
import PaperContainerHeader from '../components/PaperContainerHeader';
import PaperContainer from '../components/PaperContainer';
import ScoreCalendar from '../components/ScoreCalendar';
import ScoreType from '../types/ScoreType';

const useStyles = makeStyles((theme) => ({
  calendars: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

const YearOverview = () => {
  const classes = useStyles();
  const startOfYear = dayjs().startOf('year').format('YYYY-MM-DD');
  const endOfYear = dayjs().endOf('year').format('YYYY-MM-DD');

  const { data = [] } = useApi(`/api/v1/days?startDate=${startOfYear}&endDate=${endOfYear}`, () =>
    DayService.findAllForUser({ startDate: startOfYear, endDate: endOfYear }),
  );

  return (
    <PaperContainer>
      <PaperContainerHeader title={<>Dein Jahr {dayjs().format('YYYY')}</>} />

      <Box className={classes.calendars}>
        <ScoreCalendar days={data} scoreType={ScoreType.MOBILITY} />
        <ScoreCalendar days={data} scoreType={ScoreType.EXERCISE} />
      </Box>
    </PaperContainer>
  );
};

export default YearOverview;
