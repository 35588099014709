import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';

import useApi from '../hooks/useApi';
import { AuthService } from '../services/api';
import PaperContainer from '../components/PaperContainer';
import PaperContainerHeader from '../components/PaperContainerHeader';
import Avatar from '../components/Avatar';
import UserId from '../components/UserId';

import EditProfileDialog from './EditProfileDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import BadgeOverview from './BadgeOverview';
import Statistics from './Statistics';

const Profile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const { data: user } = useApi('/auth/profile', () => AuthService.getProfile());

  return (
    <>
      <PaperContainer>
        {user && (
          <>
            <PaperContainerHeader
              title={`Hey ${user.displayName}!`}
              subTitle={`🏢 ${user.organizationUser?.organization.name || 'Nicht zugeordnet'} 👥 ${
                user.teamUser?.team.name || 'Nicht zugeordnet'
              }`}
            >
              <Avatar name={user.displayName} variant="user" size={128} />
            </PaperContainerHeader>

            <UserId userId={user.id} />

            <List component="nav" style={{ display: 'inline-block' }}>
              <ListItem button onClick={() => setEditProfile(true)}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Profil bearbeiten" />
              </ListItem>
              <ListItem button onClick={() => setChangePassword(true)}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary="Passwort ändern" />
              </ListItem>
            </List>
          </>
        )}
      </PaperContainer>

      <Statistics />

      <BadgeOverview />

      {user && <EditProfileDialog open={editProfile} onClose={() => setEditProfile(false)} user={user} />}
      {user && <ChangePasswordDialog open={changePassword} onClose={() => setChangePassword(false)} userId={user.id} />}
    </>
  );
};

export default Profile;
