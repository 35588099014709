/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DayStep {
    DAY_TYPE = 'DAY_TYPE',
    TRIP_TO_WORK = 'TRIP_TO_WORK',
    TRIP_TO_HOME = 'TRIP_TO_HOME',
    SCORE = 'SCORE',
    SUBMITTED = 'SUBMITTED',
}