import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { useStateMachine } from 'little-state-machine';

import { updateTourOpen } from '../setup/global-state';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 1000,

    [theme.breakpoints.up('md')]: {
      right: theme.spacing(4),
      bottom: theme.spacing(4),
    },
  },
}));

const HelpButton = () => {
  const classes = useStyles();
  const {
    state: { tourOpen },
    actions,
  } = useStateMachine({ updateTourOpen });

  return !tourOpen ? (
    <Fab className={classes.root} color="primary" aria-label="Hilfe" onClick={() => actions.updateTourOpen(true)}>
      <Help />
    </Fab>
  ) : null;
};

export default HelpButton;
