/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatisticsDto } from '../models/StatisticsDto';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @returns StatisticsDto
     * @throws ApiError
     */
    public static async getUserStatistics(): Promise<StatisticsDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/statistics`,
        });
        return result.body;
    }

}