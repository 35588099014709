/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamScoreDto } from '../models/TeamScoreDto';
import type { UserScoreDto } from '../models/UserScoreDto';
import { request as __request } from '../core/request';

export class LeaderboardService {

    /**
     * @returns TeamScoreDto
     * @throws ApiError
     */
    public static async getOrganizationTeamsLeaderboards({
        startDate,
        endDate,
        sort,
    }: {
        startDate: string,
        endDate: string,
        sort: 'MOBILITY' | 'EXERCISE',
    }): Promise<Array<TeamScoreDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/leaderboards/organization-teams`,
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'sort': sort,
            },
        });
        return result.body;
    }

    /**
     * @returns UserScoreDto
     * @throws ApiError
     */
    public static async getTeamUsersLeaderboards({
        startDate,
        endDate,
        sort,
    }: {
        startDate: string,
        endDate: string,
        sort: 'MOBILITY' | 'EXERCISE',
    }): Promise<Array<UserScoreDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/leaderboards/team-users`,
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'sort': sort,
            },
        });
        return result.body;
    }

    /**
     * @returns UserScoreDto
     * @throws ApiError
     */
    public static async getOrganizationUsersLeaderboards({
        startDate,
        endDate,
        sort,
    }: {
        startDate: string,
        endDate: string,
        sort: 'MOBILITY' | 'EXERCISE',
    }): Promise<Array<UserScoreDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/leaderboards/organization-users`,
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'sort': sort,
            },
        });
        return result.body;
    }

}