import React from 'react';
import { AppBar, alpha, makeStyles, Step, StepLabel, Stepper, Toolbar } from '@material-ui/core';
import clsx from 'clsx';

import { DayStep } from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',

    '& .MuiStepIcon-root:not(.MuiStepIcon-active):not(.MuiStepIcon-completed)': {
      color: alpha(theme.palette.common.white, 0.1),
    },

    '& .MuiStepLabel-label': {
      color: alpha(theme.palette.common.white, 0.75),
    },

    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.common.white,
    },

    '& .MuiStepLabel-label.MuiStepLabel-completed': {
      color: theme.palette.common.white,
    },

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      justifyContent: 'center',

      '& .MuiStepConnector-root': {
        display: 'none',
      },
    },
  },
  step: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepActive: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type DayStepperProps = { activeStep: number; finishedSteps: DayStep[] };

const DayStepper = ({ activeStep, finishedSteps }: DayStepperProps) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.root} elevation={6}>
      <Toolbar>
        <Stepper className={classes.stepper} activeStep={activeStep}>
          {[
            { step: DayStep.DAY_TYPE, label: 'Wo hast du gearbeitet?' },
            { step: DayStep.TRIP_TO_WORK, label: 'Arbeitsweg' },
            { step: DayStep.TRIP_TO_HOME, label: 'Heimweg' },
            { step: DayStep.SCORE, label: 'Punkte kassieren!' },
          ].map(({ step, label }, index) => (
            <Step
              key={step}
              completed={finishedSteps.includes(step)}
              className={clsx(
                classes.step,
                (index === activeStep || (index === 3 && activeStep > 3)) && classes.stepActive,
              )}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Toolbar>
    </AppBar>
  );
};

export default DayStepper;
