import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { UpdateUserDto, User, UserService } from '../services/api';
import AlertDialog from '../components/AlertDialog';
import Avatar from '../components/Avatar';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  avatarBox: {
    width: theme.spacing(8),
    marginLeft: theme.spacing(1),
  },
}));

type ProfileFormProps = { open: boolean; onClose: () => void; user: User };

const EditProfileDialog = ({ open, onClose, user }: ProfileFormProps) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<Pick<UpdateUserDto, 'displayName'>>({
    defaultValues: { displayName: user.displayName },
  });

  const { ref: displayNameRef, ...displayNameRegister } = register('displayName', { required: true });

  return (
    <AlertDialog
      id="edit-profile-dialog"
      open={open}
      onClose={onClose}
      maxWidth="xs"
      title="Profil bearbieten"
      submitIcon={<Check />}
      submitLabel="Speichern"
      description={
        <Box display="flex" justifyContent="space-between" alignItems="center" component="span">
          <Box component="span">Dein Profilbild wird automatisch basierend auf dem Anzeigenamen generiert.</Box>

          <Box component="span" className={classes.avatarBox}>
            <Avatar name={watch('displayName') || user.displayName} variant="user" size={64} />
          </Box>
        </Box>
      }
    >
      <form
        id="edit-profile-dialog-form"
        onSubmit={handleSubmit(async (requestBody) => {
          await UserService.update({ id: user.id, requestBody });

          onClose();
        })}
      >
        <TextField
          label="Anzeigename"
          type="text"
          fullWidth
          margin="dense"
          className={classes.textField}
          variant="outlined"
          error={!!errors.displayName}
          helperText="Unter diesem Namen finden dich deine Teammitglieder im Wettkampfbereich"
          inputRef={displayNameRef}
          {...displayNameRegister}
        />
      </form>
    </AlertDialog>
  );
};

export default EditProfileDialog;
