import React from 'react';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';

import ScoreType from '../types/ScoreType';
import LabelType from '../types/LabelType';
import Labels from '../services/Labels';
import { ReactComponent as Badge1 } from '../assets/badges/badge-1.svg';
import { ReactComponent as Badge2 } from '../assets/badges/badge-2.svg';
import { ReactComponent as Badge3 } from '../assets/badges/badge-3.svg';
import { Badge, BadgeType, BadgeValueDto, Mobility } from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`,
    flex: '1',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  disabled: {
    borderLeftColor: theme.palette.grey.A100,
    filter: 'saturate(0)',

    '& $badge': {
      color: theme.palette.grey.A100,
    },
  },
  badgeWrapper: {
    position: 'relative',
    flex: '0 0 auto',
    display: 'flex',
    width: theme.spacing(16),
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  badge: {
    width: '100%',
    height: '100%',
  },
  badgeContent: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    textAlign: 'center',
    fontSize: '1.5rem',
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    display: 'block',
  },
  description: {
    marginTop: theme.spacing(1),
  },
}));

type BadgeCardProps = {
  badge: Pick<Badge, 'type'> & Partial<Badge>;
  disabled?: boolean;
  createdAt?: boolean;
};

const BadgeCard = ({ badge, disabled, createdAt }: BadgeCardProps) => {
  const classes = useStyles();
  let title;
  let description;
  let Component;
  let emojis;

  switch (badge.type) {
    case BadgeType.PERFECT_DAY: {
      title = 'Perfekter Tag';
      description = `Volle Punktzahl für ${Labels.scoreType(
        ScoreType.MOBILITY,
        LabelType.EMOJI_TEXT,
      )} und ${Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI_TEXT)}!`;
      Component = Badge1;
      emojis =
        Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI) + Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI);

      break;
    }

    case BadgeType.EXERCISE_DISTANCE: {
      const { value } = badge.properties as BadgeValueDto;
      title = { 50: 'Sport ist Mord', 250: 'Supersportler*in', 1000: 'Olympiawürdig' }[value];
      description = `WOW! Du hast insgesamt ${value} km mit eigener ${Labels.scoreType(
        ScoreType.EXERCISE,
        LabelType.EMOJI_TEXT,
      )} (${[
        Labels.mobility(Mobility.WALK, LabelType.EMOJI),
        Labels.mobility(Mobility.BICYCLE, LabelType.EMOJI),
        Labels.mobility(Mobility.ELECTRIC_BICYCLE, LabelType.EMOJI),
      ].join(', ')}) zurückgelegt!`;
      Component = { 50: Badge1, 250: Badge2, 1000: Badge3 }[value];
      emojis = Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI) + { 50: '🥉', 250: '🥈', 1000: '🥇' }[value];
      break;
    }

    case BadgeType.PUBLIC_TRANSPORT_DISTANCE: {
      const { value } = badge.properties as BadgeValueDto;
      title = { 100: 'Held*in des Nahverkehrs', 500: 'Öffi-Liebhaber*in', 5000: 'König*in der Langstrecke' }[value];
      description = `Du hast insgesamt ${value} km mit öffentlichen Verkehrsmitteln (${[
        Labels.mobility(Mobility.BUS, LabelType.EMOJI),
        Labels.mobility(Mobility.TRAM, LabelType.EMOJI),
        Labels.mobility(Mobility.METRO, LabelType.EMOJI),
        Labels.mobility(Mobility.TRAIN, LabelType.EMOJI),
      ].join(', ')}) zurückgelegt.`;
      Component = { 100: Badge1, 500: Badge2, 5000: Badge3 }[value];
      emojis = `🚉${{ 100: '🥉', 500: '🥈', 5000: '🥇' }[value]}`;
      break;
    }

    default:
      break;
  }

  return (
    <Card elevation={3} className={clsx(classes.root, disabled && classes.disabled)}>
      <Box className={classes.badgeWrapper}>
        {Component && <Component className={classes.badge} />}
        <Box className={classes.badgeContent}>{emojis}</Box>
      </Box>
      <Box>
        <Typography variant="h4">{title}</Typography>
        {createdAt && (
          <>
            <span className={classes.subTitle}>Erhalten am {dayjs(badge.createdAt).format('DD.MM.YYYY')}</span>
          </>
        )}
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Card>
  );
};

export default BadgeCard;
