import React, { ReactNode } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '& .MuiButton-startIcon': {
      transition: 'color ease 300ms',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(25),
      marginBottom: theme.spacing(0.5),
      fontSize: '1.25rem',
    },

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  active: {
    '& .MuiButton-startIcon': {
      color: theme.palette.primary.main,
    },
  },
}));

type NavigationLinkProps = { title: string; to: string; icon: ReactNode; onClick: () => void };

const NavigationLink = ({ title, to, icon, onClick }: NavigationLinkProps) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      startIcon={icon}
      onClick={onClick}
      component={NavLink}
      exact
      to={to}
      activeClassName={classes.active}
    >
      {title}
    </Button>
  );
};

export default NavigationLink;
