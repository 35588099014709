import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import useApi from '../hooks/useApi';
import { DayType, Mobility, StatisticsService } from '../services/api';
import TripPieChart from '../components/TripPieChart';
import PaperContainerHeader from '../components/PaperContainerHeader';
import PaperContainer from '../components/PaperContainer';
import ScoreType from '../types/ScoreType';
import LabelType from '../types/LabelType';
import Labels from '../services/Labels';
import TripPieChartLegend from '../components/TripPieChartLegend';

const useStyles = makeStyles((theme) => ({
  pieChart: {
    height: theme.spacing(50),
  },
  value: {
    fontWeight: 'bold',
    fontSize: '2rem',

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  label: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: theme.palette.grey.A200,

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

const Statistics = () => {
  const classes = useStyles();
  const { data } = useApi('/api/v1/statistics', () => StatisticsService.getUserStatistics());

  const items = [
    {
      value: `${data?.totalScore.MOBILITY} ${Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI)}`,
      label: `Total ${Labels.scoreType(ScoreType.MOBILITY, LabelType.TEXT)}`,
    },
    {
      value: `${data?.totalScore.EXERCISE} ${Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI)}`,
      label: `Total ${Labels.scoreType(ScoreType.EXERCISE, LabelType.TEXT)}`,
    },
    {
      value: `${data?.averageScore.MOBILITY.toLocaleString()} ${Labels.scoreType(ScoreType.MOBILITY, LabelType.EMOJI)}`,
      label: `⌀ ${Labels.scoreType(ScoreType.MOBILITY, LabelType.TEXT)}`,
    },
    {
      value: `${data?.averageScore.EXERCISE.toLocaleString()} ${Labels.scoreType(ScoreType.EXERCISE, LabelType.EMOJI)}`,
      label: `⌀ ${Labels.scoreType(ScoreType.EXERCISE, LabelType.TEXT)}`,
    },
    {
      value: `${data?.dayTypeCount.COMMUTED}x ${Labels.dayType(DayType.COMMUTED, LabelType.EMOJI)}`,
      label: `${Labels.dayType(DayType.COMMUTED, LabelType.TEXT)}`,
    },
    {
      value: `${data?.dayTypeCount.HOME_OFFICE}x ${Labels.dayType(DayType.HOME_OFFICE, LabelType.EMOJI)}`,
      label: `${Labels.dayType(DayType.HOME_OFFICE, LabelType.TEXT)}`,
    },
    {
      value: `${data?.totalDistanceExercise.toLocaleString()} km`,
      label: (
        <>
          Aktive Bewegung
          <br />(
          {[
            Labels.mobility(Mobility.WALK, LabelType.EMOJI),
            Labels.mobility(Mobility.BICYCLE, LabelType.EMOJI),
            Labels.mobility(Mobility.ELECTRIC_BICYCLE, LabelType.EMOJI),
          ].join(', ')}
          )
        </>
      ),
    },
    {
      value: `${data?.totalDistancePublicTransport.toLocaleString()} km`,
      label: (
        <>
          Öffentliche Verkehrsmittel
          <br />(
          {[
            Labels.mobility(Mobility.BUS, LabelType.EMOJI),
            Labels.mobility(Mobility.TRAM, LabelType.EMOJI),
            Labels.mobility(Mobility.METRO, LabelType.EMOJI),
            Labels.mobility(Mobility.TRAIN, LabelType.EMOJI),
          ].join(', ')}
          )
        </>
      ),
    },
  ];

  const segments = Object.entries(data?.totalDistancePerMobility || []).map(([mobility, distance]) => ({
    mobility: mobility as Mobility,
    distance: distance as number,
  }));

  return data ? (
    <PaperContainer>
      <PaperContainerHeader title="Statistiken" />

      <Grid container alignItems="center" spacing={2}>
        <Grid item md={8}>
          <Grid container spacing={2}>
            {items.map(({ value, label }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} sm={6}>
                <Box className={classes.value}>{value}</Box>
                <Box className={classes.label}>{label}</Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className={classes.pieChart}>
            <TripPieChart segments={segments} />
          </Box>
          {segments.filter(({ distance }) => distance > 0).length > 0 && <TripPieChartLegend />}
        </Grid>
      </Grid>
    </PaperContainer>
  ) : null;
};

export default Statistics;
