import { createStore, GlobalState } from 'little-state-machine';

import { User } from '../services/api';

createStore({
  user: undefined,
  tourOpen: false,
});

export const updateUser = (state: GlobalState, payload: User | undefined): GlobalState => ({
  ...state,
  user: payload,
});

export const updateTourOpen = (state: GlobalState, payload: boolean): GlobalState => ({
  ...state,
  tourOpen: payload,
});
