/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Mobility {
    WALK = 'WALK',
    BICYCLE = 'BICYCLE',
    ELECTRIC_BICYCLE = 'ELECTRIC_BICYCLE',
    BUS = 'BUS',
    TRAM = 'TRAM',
    METRO = 'METRO',
    TRAIN = 'TRAIN',
    CARPOOL = 'CARPOOL',
    ELECTRIC_MOTORCYCLE = 'ELECTRIC_MOTORCYCLE',
    ELECTRIC_SCOOTER = 'ELECTRIC_SCOOTER',
    ELECTRIC_CAR = 'ELECTRIC_CAR',
    MOTORCYCLE = 'MOTORCYCLE',
    SCOOTER = 'SCOOTER',
    CAR = 'CAR',
}