import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';

import LeaderboardFilter from '../components/LeaderboardFilter';
import useApi from '../hooks/useApi';
import { AuthService, LeaderboardService } from '../services/api';
import LeaderboardTable from '../components/LeaderboardTable';
import PaperContainer from '../components/PaperContainer';
import { LeaderboardAggregation, LeaderboardFilterSettings } from '../types/LeaderboardFilterSettings';
import PaperContainerHeader from '../components/PaperContainerHeader';
import DateNavigation from '../components/DateNavigation';
import ScoreType from '../types/ScoreType';

const Leaderboard = () => {
  const [weekOffset, setWeekOffset] = useState(0);
  const { setValue, watch } = useForm<LeaderboardFilterSettings>({
    defaultValues: {
      aggregation: LeaderboardAggregation.ORGANIZATION_TEAMS,
      sort: ScoreType.MOBILITY,
    },
  });
  const aggregation = watch('aggregation');
  const sort = watch('sort');

  const startDate = dayjs().add(weekOffset, 'weeks').weekday(0);
  const endDate = startDate.add(4, 'days');
  const startDateString = startDate.format('YYYY-MM-DD');
  const endDateString = endDate.format('YYYY-MM-DD');

  const { data: user } = useApi('/auth/profile', () => AuthService.getProfile());

  const { data: organizationTeamScores = [] } = useApi(
    `/api/v1/leaderboards/organization-teams?startDate=${startDateString}&endDate=${endDateString}&sort=${sort}`,
    () =>
      LeaderboardService.getOrganizationTeamsLeaderboards({ startDate: startDateString, endDate: endDateString, sort }),
  );

  const { data: teamUserScores = [] } = useApi(
    `/api/v1/leaderboards/team-users?startDate=${startDateString}&endDate=${endDateString}&sort=${sort}`,
    () => LeaderboardService.getTeamUsersLeaderboards({ startDate: startDateString, endDate: endDateString, sort }),
  );

  const { data: organizationUserScores = [] } = useApi(
    `/api/v1/leaderboards/organization-users?startDate=${startDateString}&endDate=${endDateString}&sort=${sort}`,
    () =>
      LeaderboardService.getOrganizationUsersLeaderboards({ startDate: startDateString, endDate: endDateString, sort }),
  );

  return (
    <PaperContainer>
      <PaperContainerHeader
        title={
          <>
            Wettkampf{' '}
            <Box component="span" whiteSpace="nowrap">
              {startDate.format('DD.MM.')} – {endDate.format('DD.MM.')}
            </Box>
          </>
        }
      >
        <DateNavigation
          onPrevious={() => setWeekOffset((prevState) => prevState - 1)}
          onCurrent={() => setWeekOffset(0)}
          onNext={() => setWeekOffset((prevState) => prevState + 1)}
          previousTitle="Vorherige Woche anzeigen"
          currentTitle="Aktuelle Woche anzeigen"
          nextTitle="Nächste Woche anzeigen"
          disabledNext={weekOffset >= 0}
        />
      </PaperContainerHeader>

      <LeaderboardFilter setValue={setValue} aggregation={aggregation} sort={sort} />

      {(aggregation === LeaderboardAggregation.TEAM_USERS ||
        LeaderboardAggregation.ORGANIZATION_USERS === aggregation) && (
        <LeaderboardTable
          scores={
            {
              [LeaderboardAggregation.TEAM_USERS]: teamUserScores,
              [LeaderboardAggregation.ORGANIZATION_USERS]: organizationUserScores,
            }[aggregation]
          }
          ownId={user?.id}
          getScoreId={(scoreDto) => scoreDto.user.id}
          getScoreAvatar={(scoreDto) => scoreDto.user.displayName}
          getScoreName={(scoreDto) => scoreDto.user.displayName}
          aggregation={aggregation}
        />
      )}

      {aggregation === LeaderboardAggregation.ORGANIZATION_TEAMS && (
        <LeaderboardTable
          scores={organizationTeamScores}
          ownId={user?.teamUser?.team?.id}
          getScoreId={(scoreDto) => scoreDto.team.id}
          getScoreAvatar={(scoreDto) => scoreDto.team.name}
          getScoreName={(scoreDto) => (
            <>
              {scoreDto.team.name}{' '}
              <Box component="span" whiteSpace="nowrap">
                ({scoreDto.team.usersCount} 👥 )
              </Box>
            </>
          )}
          aggregation={LeaderboardAggregation.ORGANIZATION_TEAMS}
        />
      )}
    </PaperContainer>
  );
};

export default Leaderboard;
