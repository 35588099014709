import React from 'react';
import { Check } from '@material-ui/icons';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { Trip, TripDirection, TripService } from '../services/api';
import AlertDialog from '../components/AlertDialog';
import TripView from '../components/TripView';
import TripWrapperSingle from '../components/TripWrapperSingle';

const useStyles = makeStyles((theme) => ({
  trip: {
    marginTop: theme.spacing(2),
  },
}));

type CreateTripDialogProps = {
  trip?: Pick<Trip, 'direction' | 'segments'>;
  onClose: (newTrip?: Promise<Trip>) => void;
};

const CreateTripDialog = ({ trip, onClose }: CreateTripDialogProps) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ name: string }>();
  const { ref: nameRef, ...nameRegister } = register('name', { required: true });

  return (
    <AlertDialog
      id="create-trip-dialog"
      open={!!trip}
      title="Vorlage speichern"
      description={
        <>
          Speichere deinen Weg als Vorlage um ihn an anderen Tagen einfach wiederverwenden zu können. Vorlagen können
          auch gespiegelt für die jeweils andere Richtung genutzt werden.
        </>
      }
      onClose={onClose}
      submitIcon={<Check />}
      submitLabel="Speichern"
    >
      <form
        id="create-trip-dialog-form"
        onSubmit={handleSubmit(async ({ name }) => {
          onClose(
            trip &&
              TripService.create({
                requestBody: {
                  ...trip,
                  name,
                },
              }),
          );
        })}
      >
        <TextField
          fullWidth
          margin="dense"
          label="Name der Vorlage"
          required
          variant="outlined"
          InputLabelProps={{ required: false }}
          inputRef={nameRef}
          {...nameRegister}
          error={!!errors?.name}
        />

        <Box className={classes.trip}>
          <TripWrapperSingle direction={trip?.direction || TripDirection.TO_WORK}>
            <TripView segments={trip?.segments || []} />
          </TripWrapperSingle>
        </Box>
      </form>
    </AlertDialog>
  );
};

export default CreateTripDialog;
