import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';

import PaperContainer from '../components/PaperContainer';
import DateNavigation from '../components/DateNavigation';
import useApi from '../hooks/useApi';
import { DayService } from '../services/api';
import WeekOverviewDay from '../components/WeekOverviewDay';
import PaperContainerHeader from '../components/PaperContainerHeader';

const useStyles = makeStyles((theme) => ({
  days: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    },
  },
  day: {
    fontSize: '3em',

    border: `${theme.spacing(0.25)}px solid ${theme.palette.common.white}`,
    transition: 'border-color ease 300ms',
    '&:hover': {
      borderColor: theme.palette.grey[100],
    },
  },
}));

const WeekOverview = () => {
  const classes = useStyles();
  const [weekOffset, setWeekOffset] = useState(0);

  const startDate = dayjs().add(weekOffset, 'weeks').weekday(0);
  const startDateString = startDate.format('YYYY-MM-DD');

  const days = [];
  for (let i = 0; i < 5; i += 1) {
    days.push(startDate.add(i, 'days'));
  }

  const endDate = days[4];
  const endDateString = endDate.format('YYYY-MM-DD');

  const { data = [] } = useApi(`/api/v1/days?startDate=${startDateString}&endDate=${endDateString}`, () =>
    DayService.findAllForUser({ startDate: startDateString, endDate: endDateString }),
  );

  return (
    <PaperContainer>
      <PaperContainerHeader
        title={
          <>
            Deine Woche{' '}
            <Box component="span" whiteSpace="nowrap">
              {startDate.format('DD.MM.')} – {endDate.format('DD.MM.')}
            </Box>
          </>
        }
      >
        <DateNavigation
          onPrevious={() => setWeekOffset((prevState) => prevState - 1)}
          onCurrent={() => setWeekOffset(0)}
          onNext={() => setWeekOffset((prevState) => prevState + 1)}
          previousTitle="Vorherige Woche anzeigen"
          currentTitle="Aktuelle Woche anzeigen"
          nextTitle="Nächste Woche anzeigen"
          disabledNext={weekOffset >= 0}
        />
      </PaperContainerHeader>
      <Box className={classes.days} data-tour="week-overview-days">
        {days.map((date) => (
          <WeekOverviewDay
            key={date.toISOString()}
            date={date}
            day={data.find(({ date: dayDate }) => dayDate === date.format('YYYY-MM-DD'))}
          />
        ))}
      </Box>
    </PaperContainer>
  );
};

export default WeekOverview;
