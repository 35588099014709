import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

import { DayType } from '../services/api';
import Labels from '../services/Labels';
import LabelType from '../types/LabelType';

import DayTypeSelectItem from './DayTypeSelectItem';
import SubmitButtons from './SubmitButtons';
import PageTour from './PageTour';

const useStyles = makeStyles((theme) => ({
  items: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  item: {
    flexShrink: 0,
    height: theme.spacing(40),
    width: theme.spacing(30),
    padding: theme.spacing(4),
    border: `${theme.spacing(0.5)}px solid ${theme.palette.grey[300]}`,

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  typeEmojis: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  typeLabel: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type DayTypeProps = {
  type?: DayType;
  onSelect: (type: DayType) => void;
  onNext: () => void;
};

const DayTypeSelect = ({ type, onSelect, onNext }: DayTypeProps) => {
  const classes = useStyles();

  return (
    <>
      <Box data-tour="day-type-select" className={classes.items}>
        {[DayType.COMMUTED, DayType.HOME_OFFICE, DayType.DAY_OFF].map((dayType) => (
          <DayTypeSelectItem
            key={dayType}
            emojis={Labels.dayType(dayType, LabelType.EMOJI)}
            label={Labels.dayType(dayType, LabelType.TEXT)}
            selected={type === dayType}
            onClick={() => onSelect(dayType)}
          />
        ))}
      </Box>
      <SubmitButtons
        right={
          <Button
            disabled={!type}
            endIcon={<NavigateNext />}
            color="secondary"
            variant="contained"
            disableElevation
            onClick={onNext}
            data-tour="day-type-select-submit"
          >
            Weiter
          </Button>
        }
      />

      <PageTour
        steps={[
          {
            selector: '[data-tour="day-type-select"]',
            content: (
              <Typography>
                Zuerst gibst du an, ob du an diesem Tag gependelt bist, Zuhause gearbeitet hast oder ob du abwesend
                warst.
              </Typography>
            ),
          },
          {
            selector: '[data-tour="day-type-select-submit"]',
            content: <Typography>Wenn du deine Auswahl getroffen hast, geht es hier weiter.</Typography>,
          },
        ]}
      />
    </>
  );
};

export default DayTypeSelect;
