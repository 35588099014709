import React, { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ({ numberOfColumns }: { numberOfColumns: number }) =>
      `repeat(${numberOfColumns}, minmax(0, 1fr))`,
    marginTop: theme.spacing(4),

    '& .MuiButton-root': {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: theme.spacing(24),
      },

      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  },
}));

type SubmitButtonsProps = { left?: ReactNode; right?: ReactNode };

const SubmitButtons = ({ left, right }: SubmitButtonsProps) => {
  const classes = useStyles({ numberOfColumns: left && right ? 2 : 1 });

  return (
    <Box className={classes.root}>
      {left && (
        <Box display="flex" justifyContent="flex-start">
          {left}
        </Box>
      )}

      {right && (
        <Box display="flex" justifyContent="flex-end">
          {right}
        </Box>
      )}
    </Box>
  );
};

export default SubmitButtons;
