import React, { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  segmentLine: {
    width: theme.spacing(6),
    position: 'relative',
    flexShrink: 0,

    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8),
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      left: '50%',
      transform: 'translateX(-50%)',
      width: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
}));

type TripWrapperLineProps = {
  children?: ReactNode;
};

const TripWrapperLine = ({ children }: TripWrapperLineProps) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.segmentLine} />
      <Box style={{ flex: '1 1 0', minWidth: 0 }}>{children}</Box>
    </Box>
  );
};

export default TripWrapperLine;
