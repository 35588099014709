/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginUserDto } from '../models/LoginUserDto';
import type { User } from '../models/User';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @returns User The user has been successfully logged in.
     * @throws ApiError
     */
    public static async login({
        requestBody,
    }: {
        requestBody: LoginUserDto,
    }): Promise<User> {
        const result = await __request({
            method: 'POST',
            path: `/auth/login`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static async getProfile(): Promise<User> {
        const result = await __request({
            method: 'GET',
            path: `/auth/profile`,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async logout(): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/auth/logout`,
        });
        return result.body;
    }

}