import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { Day } from '../services/api';

import BadgeCard from './BadgeCard';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

type DayBadgesProps = { day: Day };

const DayBadges = ({ day }: DayBadgesProps) => {
  const classes = useStyles();

  return day.badges.length > 0 ? (
    <Box className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Auszeichnungen
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {day.badges.map((badge) => (
          <Grid key={badge.id} item sm={6}>
            <BadgeCard badge={badge} />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null;
};

export default DayBadges;
