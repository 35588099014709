import { createTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core';
import '@fontsource/nunito/latin-400.css';
import '@fontsource/nunito/latin-700.css';
import { Theme } from '@nivo/core';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    tertiary: SimplePaletteColorOptions;
    quaternary: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    tertiary: SimplePaletteColorOptions;
    quaternary: SimplePaletteColorOptions;
  }
}

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#2ec4b6',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffb203',
        contrastText: '#ffffff',
      },
      tertiary: {
        main: '#1d3557',
        contrastText: '#ffffff',
      },
      quaternary: {
        main: '#e63946',
        contrastText: '#ffffff',
      },
      background: {
        default: '#fdfffc',
      },
      text: {
        primary: '#011627',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['Nunito', 'sans-serif'].join(','),

      h1: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {
      MuiButton: {
        label: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
    },
  }),
);

export const nivoTheme: Theme = {
  fontFamily: theme.typography.fontFamily,
  textColor: theme.palette.text.primary,
  labels: { text: { fontSize: '1rem' } },
  tooltip: {
    container: {
      background: '#333',
    },
  },
};

export default theme;
